
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../../stores';

@Component({
  components: {},
})

export default class DeleteProfile extends Vue {
  /**
   * Fired when this module is created
   */
  created() {
    this.delete();
  }
  /**
   * Deletes the selected profile
   */
  delete() {
    return stores.accounts.deleteAccountProfile(Number(this.$route.params.acc), Number(this.$route.params.id))
      .then(() => {
        this.$router.push({name: 'accounts.show', params: {id: this.$route.params.acc}});
      })
      .catch((reason) => {
        console.error('Could not delete', reason);
      });
  }
}
