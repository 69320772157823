
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';

@Component({
  components: {},
})

export default class DeleteAdmin extends Vue {
  /**
   * Fired when this module is created
   */
  created() {
    this.delete();
  }

  delete() {
    if (stores.admin.id !== Number(this.$route.params.id)) {
      return stores.admins.delete(Number(this.$route.params.id))
        .then(() => {
          this.$router.push({name: 'admins'});
        })
        .catch((reason) => {
          console.error('Could not delete', reason);
        });
    } else {
      console.log('You can not delete yourself');
    }
  }
}
