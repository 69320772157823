
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import vPagination from '@/components/VuePlainPagination.vue';
import Axios from 'axios';
import NevronHeader from '@/components/NevronHeader.vue';
import RecorderModule from '@/stores/modules/Recorders';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';

@Component({
  components: {
    vPagination,
    NevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
  },
})

export default class TVRecordersList extends Vue {
  @Prop()
  module!: RecorderModule;

  watchSkip = true;
  response: any = null;
  items: IRecorder[] = [];
  perpage = 10;

  search: any = {
    query: '',
    current: '',
  };

  indexList: number[] = [];
  allChecked: boolean = false;

  @Watch('response.currentPage', { immediate: true })
  ChangedPage() {
    if (this.watchSkip) {
      this.watchSkip = false;
    } else {
      this.search.query = this.search.current;
      this.fetchData(this.response.currentPage, this.search.current, this.perpage);
    }
  }
  perPageChange() {
    this.fetchData(1, this.search.query, this.perpage);
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    // set search query from URL
    if (this.$route.query.search) {
        this.search.query = this.$route.query.search;
        this.search.current = this.$route.query.search;
    }

    // set page from URL
    let index = 1;
    if (this.$route.query.page) {
      index = Number(this.$route.query.page);
    }

    // load data
    this.fetchData(index, this.search.current, this.perpage);
  }

  refresh() {
    this.fetchData(null, null, 10);
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = [];
      for (let index = 0; index < this.items.length; index++) {
        this.indexList.push(index);
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
      if (this.indexList.length === this.items.length) {
         this.allChecked = true;
      } else {
         this.allChecked = false;
      }
  }

  /**
   * Fetches data on the specified page and with the specified query
   */
  fetchData(index: number | null, search: string | null, perpage: number | 10) {
    return this.module.fetchData(index, search, perpage)
      .then((response) => {
        // wrong page
        if (response.currentPage < 0) {
          this.fetchData(1, search, perpage);
        } else if (response.currentPage > response.lastPage) {
          this.fetchData(response.lastPage, search, perpage);
        } else {
          this.response = response;
          this.items = this.response.data;

          // TODO to je treba rešiti lepše
          // če je page=1 potem tega ne dodamo :)
          if (this.search.query === '') {
            this.$router.push({query: {page: String(this.response.currentPage)}});
          } else {
            this.$router.push({query: {page: String(this.response.currentPage), search: this.search.query}});
          }

          this.search.current = this.search.query;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

}
