
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';

@Component({
  components: {},
})

export default class LogoutItem extends Vue {
  /**
   * Fired when this module is created
   */
  created() {
    this.logout();
  }

  logout() {
    return stores.admin.logout()
      .then(() => {
        localStorage.removeItem('menus');
        console.log('user logged out :(');
      })
      .catch((reason) => {
        console.error('Could not logout', reason);
      });
  }
}
