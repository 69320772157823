
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import {Setting} from '@/stores/Setting';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})
export default class EpgSetting extends Vue {
  epgTime: ISetting = new Setting();
  epgDayLimit: ISetting = new Setting();
  stayTime: ISetting = new Setting();

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('cron-jon-settings');
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'epg_time') {
        this.epgTime = data[index];
      } else if (data[index].key === 'epg_day_limit') {
        this.epgDayLimit = data[index];
      } else if (data[index].key === 'stay_time') {
        this.stayTime = data[index];
      }
    }
  }

  updateSetting(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        console.log('setting updated successfully..');
      });
  }
}
