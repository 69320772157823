
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import Skeleton from '../Skeleton.vue';

@Component({
  components: {Skeleton},
})

export default class ProjectsItem extends Vue {
  config = stores.globalConfig.config;
  master = stores.master;

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };

  deleteProject(project: IManagerProject) {
    if (confirm(`Permanently delete project ${project.name}?`)) {
      this.master.projects = null;
      this.master.deleteProject(project.id)
        .then((response) => {
          this.master.getProjects();
        });
    }
    return;
  }

  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }
}
