
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditFolder extends Vue {
  @Prop()
    folder!: any;

    localFolder!: any;
    errorMessage = false;

  @Watch('folder.id', {immediate: true})
    prepair() {
      this.localFolder = JSON.parse(JSON.stringify(this.folder));
    }

  save() {
    if (this.localFolder.name.length > 0) {
      this.$emit('save', this.localFolder);
      this.errorMessage = false;
    } else {
      this.errorMessage = true;
    }
  }
  cancel(): void {
    this.localFolder = JSON.parse(JSON.stringify(this.folder));
    // @ts-ignore
    this.$children[0].close();
    this.errorMessage = false;
  }
}
