
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import AttachImage from '@/modules/Media/Attach.vue';
import axiosInstance from '@/helpers/axios';

@Component({
  components: {
    AttachImage,
  },
})
export default class NevronImageField extends Vue {
  @Prop()
  module!: IModule;

  @Prop({default: null})
  image!: IMediaItem | null;

  @Prop({default: null})
  title!: string;

  localImage: IMediaItem | null = null;

  loading = false;

  mounted() {
    this.localImage = JSON.parse(JSON.stringify(this.image));
  }

  get backupMediaFolderName() {
    const routeName = this.module.routeName;
    if (routeName) {
      return `${routeName[0].toUpperCase()}${routeName.slice(1)}`;
    }
  }

  @Watch('image')
  setLocalImage(image: IMediaItem) {
    this.localImage = JSON.parse(JSON.stringify(this.image));
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  imageDropped(meta: any, image: any) {
    this.imageSelected(image);
  }

  imageFromMediaLibrary(result: any) {
    this.imageSelected(result.result);
  }

  imageSelected(image: IMediaItem) {
    this.localImage = image;
    this.loading = false;
    this.$emit('selected', image);
  }

  openGalleryModal() {
    // @ts-ignore
    this.$refs.attach.$children[0].open();
  }
}
