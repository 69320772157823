
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditDevice extends Vue {
  @Prop()
    dev!: IDevice;
  @Prop()
    types!: IDeviceType[];

  newDevice: IDevice | null = JSON.parse(JSON.stringify(this.dev));
  deviceTypes: IDeviceType[] = [];

  @Watch('dev', { immediate: true })
    setModuleFromProps() {
      this.newDevice = JSON.parse(JSON.stringify(this.dev));
  }
  @Watch('types', { immediate: true })
    setTypes() {
      this.deviceTypes = JSON.parse(JSON.stringify(this.types));
  }

  removeDevice() {
    this.$emit('delete');
  }

  save() {
    this.$emit('save', this.newDevice);
  }
  cancel(e: any): void {
    e.preventDefault();
    this.newDevice = null;
    // @ts-ignore
    this.$children[0].close();
  }
}
