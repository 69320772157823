
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
// @ts-ignore
import EventBus from '../../EventBus';

Vue.prototype.$bus = EventBus;
@Component({
  components: {
    SweetModal,
  },
})
export default class CreateModule extends Vue {
  newModule: IModule = {
    description: '',
    id: null,
    name: '',
    note: '',
    status: 'ENABLED',
    type: 'TV_RADIO',
  };

  moduleName: boolean = false;

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  save() {
    if (this.newModule.name.length > 0) {
      stores.modules
        .createNewModule(this.newModule)
        .then((response: any) => {
          this.cancel();
          this.$router.push({name: 'modules.show', params: {id: response.data.id}});
          // @ts-ignore
          this.$router.go();
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      this.moduleName = true;
    }
  }
}
