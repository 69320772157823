
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
// @ts-ignore
import i18n from '../i18n';
import stores from '@/stores';

@Component({
  components: {
    DynamicContent,
  },
})
export default class NevronInput extends Vue {
  @Prop()
  value: any | null;
  @Prop()
  referenceKey: any | '';
  @Prop()
  placeholder: any;
  @Prop()
  classes: any;
  @Prop()
  parentType: any | '';
  @Prop()
  checkTextEmpty: any | false;

  $languages: any = '';
  localKey: any = '';
  localText: any = '';

  mounted() {
    this.localKey = this.value;
    stores.Language.fetchingTranslationsPromise
      .then((resolved: any) => {
        this.localText = Vue.prototype.translate(this.localKey);
      });
  }

  handleTranslate() {
    if (this.parentType === 'model') {
      this.$emit('openTranslator', this.localText, this.localKey, this.referenceKey);
    } else {
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    }
  }

  @Watch('value')
  updateData() {
    if (this.value !== this.localText) {
      this.localKey = this.value;
      stores.Language.fetchingTranslationsPromise
        .then((resolved: any) => {
          this.localText = Vue.prototype.translate(this.localKey);
        });
    }
  }

}
