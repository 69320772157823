
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';
import { Setting } from '@/stores/Setting';

@Component({
  components: {
    AttachImage,
    NevronHeader,
  },
})
export default class Configuration extends Vue {
  apiUrl: ISetting = new Setting();
  transcoder: ISetting = new Setting();
  recoderLink1: ISetting = new Setting();
  recoderLink2: ISetting = new Setting();
  epgTime: ISetting = new Setting();
  logo: ISetting = new Setting();
  mobileLoginBackground: ISetting = new Setting();
  mobileLogo: ISetting = new Setting();
  epgDayLimit: ISetting = new Setting();
  imageUrl: string = '';
  mobileLoginBackgrounImageUrl: string = '';
  mobileLogoImageUrl: string = '';
  attachableContent: any = null;
  contentUrl: any = '';
  selectedFolderId: any = 1;

  mounted() {
    // this.contentUrl = session.project.contentUrl;
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });

    stores.Setting.getSystemLogo().then((response) => {
      this.logo = response;
      this.imageUrl = this.logo.value;
    });
  }
  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'api_url') {
        this.apiUrl = data[index];
      } else if (data[index].key === 'transcoder_url') {
        this.transcoder = data[index];
      } else if (data[index].key === 'recoder_url_1') {
        this.recoderLink1 = data[index];
      } else if (data[index].key === 'recoder_url_2') {
        this.recoderLink2 = data[index];
      } else if (data[index].key === 'epg_time') {
        this.epgTime = data[index];
      } else if (data[index].key === 'mobile_login_background') {
        this.mobileLoginBackground = data[index];
      } else if (data[index].key === 'mobile_logo') {
        this.mobileLogo = data[index];
      } else if (data[index].key === 'epg_day_limit') {
        this.epgDayLimit = data[index];
      }
    }

    if (this.mobileLoginBackground) {
      this.mobileLoginBackgrounImageUrl = this.mobileLoginBackground.value;
    }
    if (this.mobileLogo) {
      this.mobileLogoImageUrl = this.mobileLogo.value;
    }
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }
  getHeaders() {
    return stores.File.getHeader();
  }
  updateSetting(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => console.log(response));
  }
  // open pop-up to create folder or directory
  folderRequest(res: any) {
    this.selectedFolderId = res.folderId;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    // @ts-ignore
    this.$refs.create.$children[0].open();
  }

  // Logo Setting area
  attachWithImage(res: any) {
    this.imageUrl = res.result.imageUrl;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    this.getImage(res.result.id);
  }
  getImage(id: number) {
  return stores.File.getImage(id)
    .then((response) => {
      this.imageUrl = response.imageUrl;
    })
    .catch((error) => {
      console.log(error);
    });
  }
  getImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.image.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  sendReload(files: any, result: any) {
    this.imageUrl = result.imageUrl;
    this.getImage(result.id);
  }
  // Mobile login background setting area
  attachBackgroundWithImage(res: any) {
    this.mobileLoginBackgrounImageUrl = res.result.imageUrl;
    // @ts-ignore
    this.$refs.loginBackground.$children[0].close();
    this.getBackgroundImage(res.result.id);
  }
  getBackgroundImage(id: number) {
  return stores.File.getImage(id)
    .then((response) => {
      this.mobileLoginBackgrounImageUrl = response.imageUrl;
    })
    .catch((error) => {
      console.log(error);
    });
  }
  getBackgroundImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.loginBackground.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  sendBackgroundReload(files: any, result: any) {
    this.mobileLoginBackgrounImageUrl = result.imageUrl;
    this.getBackgroundImage(result.id);
  }

  // mobile logo setting area
  attachMobileLogoWithImage(res: any) {
    this.mobileLogoImageUrl = res.result.imageUrl;
    // @ts-ignore
    this.$refs.mobileLogo.$children[0].close();
    this.getMobileLogoImage(res.result.id);
  }
  getMobileLogoImage(id: number) {
  return stores.File.getImage(id)
    .then((response) => {
      this.logo = response.imageUrl;
    })
    .catch((error) => {
      console.log(error);
    });
  }
  getMobileLogoImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.mobileLogo.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  sendMobileLogoReload(files: any, result: any) {
    this.mobileLogoImageUrl = result.imageUrl;
    this.getMobileLogoImage(result.id);
  }

  updateLogo() {
    console.log(this.imageUrl);
  }
}
