
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import axiosInstance from '../../../helpers/axios';
import TvModule from '@/stores/modules/TvModule';
import session from '@/stores/Session';

@Component({
  components: {
    SweetModal,
  },
  mixins: [ Clickaway ],
})

export default class EditChannel extends Vue {
  @Prop()
    module!: TvModule;
  @Prop()
    channel!: IChannel;

  tempChannel: IChannel = JSON.parse(JSON.stringify(this.channel));

  @Watch('channel')
    setModuleFromProps() {

      this.tempChannel = JSON.parse(JSON.stringify(this.channel));
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  sort() {
    if (this.tempChannel.id && this.tempChannel.channelNumber) {
      this.module.sortChannels(this.tempChannel.id, this.tempChannel.channelNumber);
    }
  }

  remove() {
    if (confirm('Are you sure you wish to delete this TV channel?')) {
      this.$emit('delete');
    }
  }

  openFolderSys() {
    this.$emit('openFileSys');
    // @ts-ignore
    this.$children[0].close();
  }

  sendReload(files: any, result: any) {
    this.tempChannel.imageId = result.id;
    this.$emit('sendReload', this.tempChannel);
  }

  saveEdit() {
    this.$emit('save', this.tempChannel);
    // @ts-ignore
    this.$children[0].close();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  getFilePath(): string {
      return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }
}
