
import {Component, Vue, Watch} from 'vue-property-decorator';
import DeviceEdit from '@/modules/Devices/Edit.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import TimeZones from '@/modules/TimeZones.vue';
import NevronInput from '@/components/NevronInput.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    DeviceEdit,
    virtualList,
    Skeleton,
    NevronHeader,
    NevronFooter,
    TimeZones,
    NevronInput,
    TableModalGeneric,
    TableGeneric,
  },
})

export default class DeviceDetails extends Vue {
  showConfirm: boolean = false;
  device: IDevice | null = null;
  account!: IAccount[];
  zones: any = null;
  fullRes: any = null;
  locationRes: any = null;
  searchQuery: string = '';
  CancelToken: any = Axios.CancelToken;
  source: any;
  searchInProgress: boolean = false;
  deviceTypes: IDeviceType[] = [];
  fullDashboardResponse: any = null;
  stores = stores;

  /**
   *
   */
  @Watch('$route', {immediate: false, deep: true})
  changePage() {
    this.reload(Number(this.$route.params.id));
  }

  save() {
    if (this.device) {
      const data = {
        ip: this.device.ip,
        macEth: this.device.macEth,
        macWifi: this.device.macWifi,
        timeZone: this.device.timeZone,
        firmware: this.device.firmware,
        apk: this.device.apk,
        manufacturer: this.device.manufacturer,
        serialNumber: this.device.serialNumber,
        name: this.device.name,
        locationId: this.zones ? this.zones.id : null,
      };
      return stores.Devices.updateItem(this.device.id, data)
        .then((response) => {
          console.log('success');
          if (response.responseCode === 200) {
            // @ts-ignore
            showToaster('success', this.device.name ? Vue.prototype.translate(this.device.name) : this.device?.macEth, 'successfully updated');
          } else {
            // @ts-ignore
            showToaster('danger', this.device.name ? Vue.prototype.translate(this.device.name) : this.device?.macEth, 'Fail to update', response.code);
          }
        }).catch((e: any) => {
          console.log(e);
          // @ts-ignore
          showToaster('danger', this.device.name ? Vue.prototype.translate(this.device.name) : this.device?.macEth, 'Fail to update');
        });
    }
  }

  timeZoneSelected(zone: string) {
    if (this.device) {
      this.device.timeZone = zone;
    }
  }

  saveEdit(newDevice: IDevice) {
    if (newDevice && newDevice.id) {
      return stores.Devices.saveDeviceEdit(newDevice)
        .then((response: any) => {
          console.log('You have successfully edited a device!');
          this.reload(response.id);
          // @ts-ignore
          this.$refs.edit.$children[0].close();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  attachDevices(account: IAccount) {
    if (account && this.device && this.device.id) {
      return stores.Devices.attachAccount(account.id!, this.device.id)
        .then((response: any) => {
          console.log('Successfully attached');
          // @ts-ignore
          this.$refs.accounts.$children[0].close();
          this.reload(Number(this.$route.params.id));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  attachLocation(zone: Ilocations[]) {
    this.zones = zone;
  }

  detachLocation(zone: IItem) {
    this.zones = null;
  }

  get locationIds() {
    return this.zones.map((el: IItem) => el.id);
  }

  getAccountsOnPage(index: number) {
    return stores.accounts.fetchData(index, null, 10)
      .then((response: any) => {
        this.fullRes = response;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  attacheDashboard() {
    stores.dashboards
      .fetchData(1, '', 1000000, 'Mobile')
      .then((response) => {
        this.fullDashboardResponse = response;
        // @ts-ignore
        this.$refs.dashboard.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  detachAccount() {
    if (this.device && this.device.id) {
      return stores.Devices.detachAccount(this.device.id)
        .then((response: any) => {
          console.log('Successfully detached');
          // @ts-ignore
          this.$router.go();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  searchDevicesToAttach(query: string) {
    // TODO: FIX
    // this.searchQuery = query;
    // this.CancelToken = Axios.CancelToken;
    // this.source = this.CancelToken.source();
    // console.log('query', this.searchQuery);
    // console.log('search requested');
    // if (!this.searchInProgress) {
    //   this.searchInProgress = true;
    //   setTimeout(() => {
    //     return stores.Account.searchAccounts(this.searchQuery, this.fullRes.currentPage, this.source.token)
    //       .then((response) => {
    //         console.log(this.searchQuery);
    //         this.fullRes = response;
    //         if (this.searchQuery !== '') {
    //           this.$router.push({query: {query: this.searchQuery, page: String(this.fullRes.currentPage)}});
    //         } else {
    //           this.$router.push({query: {page: String(this.fullRes.currentPage)}});
    //         }
    //         console.log('search executed');
    //         this.searchInProgress = false;
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   }, 500);
    // } else {
    //   this.source.cancel();
    // }
  }

  getAccountListToAttach(e: any) {
    // e.preventDefault();
    return stores.accounts.fetchData(null, null, 10)
      .then((response: any) => {
        this.fullRes = response;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getLocationListToAttach(e: any) {
    // e.preventDefault();
    return stores.Locations.fetchData(null, null, 10)
      .then((response: any) => {
        this.locationRes = response;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  removeDevice() {
    return stores.Devices.deleteItem(this.device?.id!)
      .then((response: any) => {
        console.log('You have successfully deleted this device!');
        // @ts-ignore
        this.$refs.edit.$children[0].close();
        this.$router.push('/devices');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  reload(id: number) {
    return stores.Devices.getSelectedDevice(id)
      .then((response: any) => {
        this.device = response;
        if (this.device && this.device.id) {
          return stores.Devices.getAccountForDevice(this.device.id);
        }
      })
      .then((response: any) => {
        if (Object.entries(response).length > 0 && response.constructor === Object) {
          this.account = response;
        }
        if (this.device && this.device.id) {
          return stores.Devices.getLocationForDevice(this.device.id);
        }
      })
      .then((response: any) => {
        if (Object.entries(response).length > 0 && response.constructor === Object) {
          this.zones = response;
        }
        return stores.Devices.getAllDeviceTypesFull();
      })
      .then((response: any) => {
        this.deviceTypes = response;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.reload(Number(this.$route.params.id));
  }
}
