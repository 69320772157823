
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import RecorderModule from '@/stores/modules/Recorders';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})

export default class EditTVRecorders extends Vue {
  @Prop()
  rec!: IRecorder;
  @Prop()
  module!: RecorderModule;

  recorder = JSON.parse(JSON.stringify(this.rec));

  @Watch('acc', { immediate: true })
    setModuleFromProps() {
      this.recorder = JSON.parse(JSON.stringify(this.rec));
  }

  save() {
    console.log('module', module);

    return this.module.editRecorders(this.recorder)
      .then((response) => {
        console.log('You have successfully edited a recorder');
        this.cancel();
      })
      .catch((e) => {
        console.log(e);
      });
  }
  cancel(): void {
    this.recorder = {
      id: null,
      name: '',
      ip: '',
      port: null,
      type: 'NEVRON',
      username: '',
      password: '',
      streams: [],
    };
    // @ts-ignore
    this.$children[0].close();
  }
}
