
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import CreateFolder from '@/modules/Media/CreateFolder.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';

@Component({
  components: {
    SweetModal,
    CreateFolder,
    NevronSearch,
    NevronSearchNoResults,
    NevronFooter,
  },
  mixins: [Clickaway],
})

export default class AttachImageTo extends Vue {
  // for attaching multiple images
  @Prop({default: false})
  isGallery!: boolean;
  // which images are already attached
  @Prop({default: () => []})
  attached!: any[];
  attahImage: any = null;
  imageDescription: any = null;
  attachImageheight: any = null;
  attachImagewidth: any = null;
  @Prop()
  module!: IModule;

  response: any = null;
  contentUrl: any = '';
  search: any = {
    query: '',
    current: '',
  };

  get backupMediaFolderName() {
    const routeName = this.module.routeName;
    if (routeName) {
      return `${routeName[0].toUpperCase()}${routeName.slice(1)}`;
    }
  }

  mounted() {
    this.getSubItems();
  }

  back() {
    return stores.Folder.getFolder(this.response.mediaFolderId)
      .then((response: any) => {
        this.response = response;
        this.response.children = this.response.folders.concat(this.response.files);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getSubItems(id: number = 0) {
    return stores.Folder.getFolder(id, this.search.query)
      .then((response: any) => {
        this.response = response;
        this.response.children = this.response.folders.concat(this.response.files);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  inAttached(file: any) {
    return this.attached.map((obj: any) => obj.id).includes(file.id);
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  sendAttach(res: any, newImage: boolean = false) {
    if (this.isGallery && this.inAttached(res)) {
      return false;
    }
    this.$emit('attach', {result: res, new: newImage});
    if (!this.isGallery) {
      this.cancel();
    }
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  transmitFinish(files: any, result: any) {
    this.attahImage = result;
    const img = new Image();
    img.src = this.attahImage.imageUrl;
    this.attachImageheight = img.height;
    let width: number;
    let height: number;
    img.onload = () => {
      // here you got the width and height
      width = img.width;
      height = img.height;
    };
    setTimeout(() => {
      this.attachImageheight = height;
      this.attachImagewidth = width;
    }, 1000);
  }

  cancel() {
    this.attahImage = null;
    // @ts-ignore
    this.$children[0].close();
  }

}
