
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import routerBase from '@/helpers/routerBase';
import globalConfig from '@/helpers/globalConfig';
// @ts-ignore
import EventBus from '../../../src/EventBus';

import {AxiosError} from 'axios';

@Component
export default class LoginItem extends Vue {
  username: string = '';
  password: string = '';
  rememberMe: boolean = false;
  session = stores.session;

  inDevelopment = globalConfig.env === 'development';

  redirectPath: string | null = null;

  errorMessage: string | null = null;

  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');

  @Watch('admin.isLoggedIn', {immediate: true})
  adminWatch() {
    this.redirect();
  }

  redirect() {
    // @ts-ignore
    this.$bus.$emit('logged', 'User logged');
    if (this.admin.isLoggedIn) {
      this.$router.replace(this.redirectPath || '/dashboard');
    }
  }

  async beforeCreate() {
    const currentProjectId = routerBase;
    await stores.master.getSpecificProject(currentProjectId)
      .then((response: any) => {
        if (response.error) {
          this.$router.replace({name: '404', query: {error: `Project \"${this.session.projectId}\" does not exist`}});
        }
      });
  }

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('login');
    this.redirectPath = this.$route.query.redirect as string;
  }

  onSubmit() {
    return stores.admin.login(this.username, this.password, this.rememberMe)
      .then((res) => {
        this.errorMessage = null;
        // adding language id
        stores.Language.activeLanguages()
          .then((response: any) => {
            console.log('active languages are', response[0].id);
            if (!stores.admin.languageId) {
              stores.admin.setLanguage(response[0].id);
            }
          });
        // menus loading for sidebar
        stores.modules.modules()
          .then((response) => {
            localStorage.setItem('menus', JSON.stringify(response));
          });
        EventBus.$emit('login');
        this.redirect();
      })
      .catch((error) => {
        console.log(error.data.message);
        this.errorMessage = error.data.message;
      });
  }

  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }

}
