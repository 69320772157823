
import {Component, Vue, Prop} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';

@Component({
  components: {
    SweetModal,
    TableGeneric,
  },
})
export default class CreateDashboard extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  @Prop()
  module: any;

  newDashboard: any = {
    id: null,
    name: '',
    active: true,
  };
  copyExistingDashboard = false;
  dashboards: any = null;
  selectDashboard = null;


  createNewDashboard() {
    console.log(this.selectDashboard, this.newDashboard.name);
    if (!this.copyExistingDashboard) {
      this.module.createNewItem(this.newDashboard)
        .then((response: any) => {
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
          this.$router.push({
            name: `${this.module.routeName}.show`,
            params: {id: response.data.id}
          });
        });
    } else {
      this.module.createCopyExistingDashboard(this.selectDashboard, this.newDashboard)
        .then((response: any) => {
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
          this.$router.push({
            name: `${this.module.routeName}.show`,
            params: {id: response.data.id}
          });
        });
    }

  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  async mounted() {
    console.log(this.module);
    await stores.dashboards.fetchData(1, '', 100, this.module.type)
      .then((response) => {
        this.dashboards = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.module.type === 'mobile') {
      stores.gFMobileComposer.fetchData(1, '', 10, 'GFmobile')
        .then((response) => {
          // console.log(response.data);
          this.dashboards.push(response.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener(
        'submit',
        (event: any) => {
          event.preventDefault();
          if (form.checkValidity() === true) {
            event.stopPropagation();
            this.createNewDashboard();
          }
          if (form.checkValidity() === false) {
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        },
        false,
      );
    });
  }
}
