
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
// import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import stores from '../../../stores';

@Component({
  components: {
    SweetModal,
    // CustomerAddressEdit,
  },
  mixins: [ Clickaway ],
})
export default class PreEditAddress extends Vue {
  @Prop()
    addresses!: ICustomerAddress[];

  customerAddresses: ICustomerAddress[] = JSON.parse(JSON.stringify(this.addresses));
  tempLoc: ICustomerAddress | null = null;

  @Watch('addresses', { immediate: true })
    setModuleFromProps() {
      this.customerAddresses = JSON.parse(JSON.stringify(this.addresses));
  }

  passCreate(newAddress: ICustomerAddress) {
    this.$emit('create', newAddress);
  }

  passEdit(newAddress: ICustomerAddress) {
    this.$emit('edit', newAddress);
  }

  setDefault(selected: ICustomerAddress) {
    this.$emit('default', selected);
  }

  setupEditlocation(address: ICustomerAddress | null) {
    this.tempLoc = null;
    if (!address) {
      this.tempLoc = {
        address: '',
        address2: '',
        city: '',
        country: '',
        post: '',
        id: null,
        customerId: null,
        default: 0,
        firstName: '',
        lastName: '',
        company: '',
      };
    } else {
      this.tempLoc = address;
    }

    // @ts-ignore
    this.$children[0].close();
    // // @ts-ignore
    // this.$refs.edit.$children[0].open();

  }

  remove(id: number) {
    this.$emit('delete', id);
    // @ts-ignore
    this.$children[0].close();
  }

  // save(): void {
  //   //  this.$emit('save', this.user)
  //    // @ts-ignore
  //   this.$parent.saveCustomer(this.user)
  //   // @ts-ignore
  //   this.$children[0].close();
  // }
  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }

}
