
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import AttachImage from '@/modules/Media/Attach.vue';
  import axiosInstance from '../../helpers/axios';
  import session from '@/stores/Session';
  import { Setting } from '@/stores/Setting';
  import Skeleton from '@/modules/Skeleton.vue';

  @Component({
    components: {
      AttachImage,
      NevronHeader,
      Skeleton,
    },
  })
  export default class GeneralSetting extends Vue {

    logo: ISetting = new Setting();
    mobileLoginBackground: ISetting = new Setting();
    mobileLogo: ISetting = new Setting();
    defaultColor: ISetting = new Setting();
    secondaryColor: ISetting = new Setting();
    noImageIcon: ISetting = new Setting();

    attachImageTo: ISetting | null = null;

    mounted() {
      this.fetchSettings();
      Vue.prototype.WebsiteTitle.setModuleName('general-configuration');
    }

    fetchSettings() {
      stores.Setting.getSettings()
        .then((response) => {
          this.settings(response);
        });
    }

    settings(data: ISetting[]) {
      for (const index in data) {
        if (data[index].key === 'logo') {
          this.logo = data[index];
          this.logo.type = 'media_file';

        } else if (data[index].key === 'mobile_login_background') {
          this.mobileLoginBackground = data[index];
          this.mobileLoginBackground.type = 'media_file';

        } else if (data[index].key === 'mobile_logo') {
          this.mobileLogo = data[index];
          this.mobileLogo.type = 'media_file';

        } else if (data[index].key === 'no_image_icon') {
          this.noImageIcon = data[index];
          this.noImageIcon.type = 'media_file';

        } else if (data[index].key === 'primary_company_color') {
        this.defaultColor = data[index];

        } else if (data[index].key === 'secondary_company_color') {
          this.secondaryColor = data[index];
        }
      }
    }

    updateSetting(setting: ISetting, newValue: string | null) {
      const data = { value: newValue, type: setting.type };
      stores.Setting.update(setting.id, data)
        .then((response) => {
          this.fetchSettings();
        });
    }

    attachWithImage(res: any) {
      this.updateSetting(this.attachImageTo!, res.result.id);
    }

    sendReload(files: any, result: any, setting: ISetting) {
      this.updateSetting(this.attachImageTo!, result.id);
    }

    getImages(setting: ISetting) {
      this.attachImageTo = setting;
      // @ts-ignore
      this.$refs.image.$children[0].open();
    }

    getFilePath(): string {
      return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
    }

    getHeaders() {
      return stores.File.getHeader();
    }
  }
