export default function getInitials(str: string) {
  let initials = '';
  if (str.indexOf(' ') >= 0) {
    const i = str.indexOf(' ');
    initials = str[0] + str[i + 1];
  } else {
    initials = str.substr(0, 2);
  }
  return initials.toUpperCase();
}
