
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import {mixin as Clickaway} from 'vue-clickaway';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import {modulesMetaData} from '@/helpers/constants';
import moment from 'moment';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import {translatedDisplayName} from '@/helpers/functions';
// @ts-ignore
import EventBus from '../EventBus';
import {showToaster} from '@/components/DashboardComposer/helpers';

Vue.prototype.$bus = EventBus;
@Component({
  components: {
    NevronSearchNoResults,
    NevronClickConfirm,
  },
  mixins: [Clickaway],
})
export default class NevronTableModalGeneric extends Vue {
  @Prop({required: true})
  instance!: IModule;

  @Prop({required: true})
  items!: IItem[] | IItem;

  @Prop({default: false})
  isPageItem!: boolean;

  @Prop({default: false})
  editActiveFlag!: boolean;

  @Prop({default: false})
  isItemCategory!: boolean;

  @Prop({default: null})
  metaKey!: string | null;

  // show active switch
  @Prop({default: true})
  hasActive!: boolean;

  // allow toggling active
  @Prop({default: false})
  toggableActive!: boolean;

  // show select checkboxes
  @Prop({default: false})
  hasSelect!: boolean;

  // emit item when clicked, instead of opening it
  @Prop({default: false})
  emitOpenItem!: boolean;

  // select item when clicked, instead of opening it
  @Prop({default: false})
  selectOpenItem!: boolean;

  // when this value is changed, selected is cleared
  @Prop({default: false})
  resetSelected!: boolean;

  // ACTIONS
  @Prop({default: false})
  hasUnlink!: boolean;
  @Prop({default: false})
  hasRemove!: boolean;

  @Prop({default: false})
  checkPopup!: boolean;

  selectedArray: IItem[] = [];
  selectedItem: IItem | null = null;
  allChecked = false;

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get tableMeta(): any {
    if (this.metaKey && this.instanceMeta[this.metaKey]) {
      return this.instanceMeta[this.metaKey];
    }
    return this.instanceMeta;
  }

  get fields(): any {
    if (!this.tableMeta.fields) {
      return this.instanceMeta.fields;
    }
    return this.tableMeta.fields;
  }

  get localItems() {
    if (Array.isArray(this.items)) {
      return this.items;
    } else if (this.items) {
      return [this.items];
    }
    return [];
  }

  get hasActiveSwitch() {
    if (this.instanceMeta.noActive || this.tableMeta.noActive) {
      return false;
    }
    return this.hasActive;
  }

  shouldOpen(fieldIndex: number) {
    const hasImage = this.fields[0].type === 'image';
    return (!hasImage && fieldIndex === 0) || (hasImage && fieldIndex === 1);
  }

  editItem(item: IItem, force = false) {
    if (!force && this.selectOpenItem) {
      this.selectItem(item);
      return;
    }
    if (this.emitOpenItem) {
      this.$emit('openItem', item);
      return;
    } else {
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: {id: String(item.id)},
      });
    }
    this.$emit('openItem', item);

  }

  // item will open when we click on the name
  openItem(item: IItem) {
    // this condition will work for pages item's link module redirection to index page
    if (this.isPageItem) {
      this.$router.push({
        name: `catalogue.items`,
        params: {moduleId: String(item.id)},
      });
    } else if (this.emitOpenItem) {
      // this condition will work for anything edit on popup model
      this.$emit('openItem', item);
      return;
    } else if (this.selectOpenItem) {
      // this condition will work for anything select specific element into the popup list
      this.selectItem(item);
      return;
    } else {
      // this condition will work for anything we want to open
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: {id: String(item.id)},
      });
    }
  }

  // item will be selected on popup when we click on the name
  selectItem(item: IItem) {
    if (this.tableMeta.selectOne) {
      this.selectedItem = item;
    } else {
      if (this.selectedArray.filter((el: IItem) => el.id === item.id).length === 0) {
        this.selectedArray.push(item);
      } else {
        this.selectedArray = this.selectedArray.filter((it: IItem) => it.id !== item.id);
      }
    }
    this.updateCheck();
  }

  // item will toggle when we are on index page
  toggleActive(item: any) {
    if (this.editActiveFlag) {
      if (this.isItemCategory) {
        item.active = Number(!item.active);
        // @ts-ignore
        this.instance.updateCategory(item.id, item)
        .then((response) => {
          if (response.responseCode === 200) {
            showToaster('success', Vue.prototype.translate(response.data.name), 'successfully updated');
          } else {
            showToaster('danger',  Vue.prototype.translate(response.data.name), 'Fail to update');
          }
        });
        if (this.instance.routeName === 'modules') {
          // @ts-ignore
          this.$bus.$emit('active');
        }
      } else {
        item.active = Number(!item.active);
        // @ts-ignore
        this.instance.updateItem(item.id, item) .then((response) => {
          if (response.responseCode === 200) {
            showToaster('success', Vue.prototype.translate(response.data.name), 'successfully updated');
          } else {
            showToaster('danger',  Vue.prototype.translate(response.data.name), 'Fail to update');
          }
        });
        if (this.instance.routeName === 'modules') {
          // @ts-ignore
          this.$bus.$emit('active');
        }
      }

    } else {
      console.log('Toggle active');
      item.active = Number(!item.active);
      // @ts-ignore
      this.instance.updateItem(item.id, {id: item.id, active: item.active});
      if (this.instance.routeName === 'modules') {
        // @ts-ignore
        this.$bus.$emit('active');
      }
    }

  }

  /* removes or appends all items ids on current page to selectedArray */
  checkAll() {
    if (!this.allChecked) {
      const notCheckedItems = this.localItems.filter((item: IItem) => !this.selectedArray.includes(item));
      this.selectedArray = this.selectedArray.concat(notCheckedItems);
      this.allChecked = true;
    } else {
      this.selectedArray = this.selectedArray.filter((item: IItem) => !this.itemIds.includes(item.id));
      this.allChecked = false;
    }
  }

  /* when item is checked */
  @Watch('items')
  updateCheck() {
    this.allChecked = this.itemIds.every((id: number) => this.selectedIds.includes(id));
  }

  @Watch('resetSelected')
  clearSelected() {
    this.selectedArray = [];
    this.selectedItem = null;
    this.allChecked = false;
  }

  @Watch('selectedArray')
  emitSelectedArray() {
    this.$emit('input', this.selectedArray);
  }

  @Watch('selectedItem')
  emitSelectedItem() {
    this.$emit('input', this.selectedItem);
  }

  getTranslatedDisplayName(item: IItem) {
    return translatedDisplayName(item);
  }

  get itemIds() {
    return this.localItems.map((item: IItem) => item.id);
  }

  get selectedIds() {
    return this.selectedArray.map((item: IItem) => item.id);
  }

  get noImageSrc() {
    const noImageSetting = stores.Setting.models.find((setting: ISetting) => setting.key === 'no_image_icon');
    return noImageSetting?.value;
  }

  formatDate(date: any) {
    return moment(date).format('DD-MM-YYYY');
  }

}
