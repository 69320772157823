
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import { Setting } from '@/stores/Setting';
  import routerBase from '@/helpers/routerBase';
  import Skeleton from '@/modules/Skeleton.vue';

  @Component({
    components: {
      NevronHeader,
      Skeleton,
    },
  })
  export default class AppSetting extends Vue {

    apiUrl = `${stores.master.url}/${routerBase}`;

    transcoder: ISetting = new Setting();
    recoderLink1: ISetting = new Setting();
    recoderLink2: ISetting = new Setting();
    clientUrl?: ISetting = new Setting();
    matomoUrl?: ISetting = new Setting();

    copied = {
      apiUrl: false,
      clientUrl: false,
      matomoUrl: false,
    };

    mounted() {
      Vue.prototype.WebsiteTitle.setModuleName('app-settings');
      // this.contentUrl = session.project.contentUrl;
      stores.Setting.getSettings()
        .then((response: any) => {
          this.settings(response);
        });
    }
    settings(data: ISetting[]) {
      // @ts-ignore
      for (const index in data) {
        if (data[index].key === 'transcoder_url') {
          this.transcoder = data[index];
        } else if (data[index].key === 'recoder_url_1') {
          this.recoderLink1 = data[index];
        } else if (data[index].key === 'recoder_url_2') {
          this.recoderLink2 = data[index];
        } else if (data[index].key === 'client_url') {
          data[index].value += `/${routerBase}`;
          this.clientUrl = data[index];
        } else if (data[index].key === 'matomo_url') {
          this.matomoUrl = data[index];
        }
      }
    }

    updateSetting(id: any, value: any) {
      stores.Setting.update(id, {value})
        .then((response: any) => {
          console.log('setting updated successfully..');
        });
    }

    copyText(text: string) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      if (text === this.apiUrl) {
        this.copied.apiUrl = true;
      } else if (text === this.clientUrl?.value) {
        this.copied.clientUrl = true;
      } else if (text === this.matomoUrl?.value) {
        this.copied.matomoUrl = true;
      }
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();

      });
    }
  }
