
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import {translatedDisplayName} from '@/helpers/functions';
import axios from '@/helpers/axios.ts';
import stores from '@/stores';

@Component({
  components: {
    NevronClickConfirm,
  },
  mixins: [Clickaway],
})
export default class NevronShowHeader extends Vue {
  @Prop({required: true})
  item!: IItem;

  @Prop({default: true})
  hasRemove!: boolean;

  @Prop({default: true})
  hasRefresh!: boolean;

  @Prop({default: true})
  hasBreadcrumb!: boolean;

  itemName = '';

  axiosInterceptor: number | null = null;

  mounted() {
    this.updateItemName();
    this.updatePageTitle();

    this.axiosInterceptor = axios.interceptors.response.use((response: any) => {
      if (response.config && ['patch', 'put'].includes(response.config.method)) {
          this.updateItemName();
      }
      return response;
    });

  }

  @Watch('item')
  updateItemName() {
    stores.Language.fetchingTranslationsPromise
      .then((resolved: any) => {
        this.itemName = translatedDisplayName(this.item);
      });
  }

  beforeDestroy() {
    if (this.axiosInterceptor) {
      axios.interceptors.response.eject(this.axiosInterceptor);
    }
  }

  @Watch('itemName')
  updatePageTitle() {
    Vue.prototype.WebsiteTitle.setTranslatedPageName(this.itemName);
  }

}
