
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import axiosInstance from '../../../helpers/axios';
import session from '@/stores/Session';

@Component({
  components: {
    SweetModal,
  },
  mixins: [ Clickaway ],
})

export default class VODImageGallery extends Vue {
  @Prop()
    movie!: IVOD;

  detach(e: any, imageID: number, index: number) {
    e.preventDefault();
    this.$emit('detach', {id: imageID, i: index});
    if (this.movie.gallery) {
          this.movie.gallery.splice(index, 1);
    }
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  openFolderSys() {
    this.$emit('openFileSys');
    // @ts-ignore
    this.$children[0].close();
  }

  sendReload(files: any, result: any) {

    this.$emit('attach', result.id);
  }

  cancel() {
    this.$emit('cancel', false);
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }
}
