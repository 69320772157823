
import {Component, Vue, Watch} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})

export default class SmsConfiguration extends Vue {
  response: any = '';
  smsDriver: IModuleSetting = {
    type: 'sms',
    key: 'sms_driver',
    value: '',
  };
  smsName: IModuleSetting = {
    type: 'sms',
    key: 'sms_name',
    value: '',
  };
  smsKey: IModuleSetting = {
    type: 'sms',
    key: 'sms_key',
    value: '',
  };
  smsSecret: IModuleSetting = {
    type: 'sms',
    key: 'sms_secret',
    value: '',
  };

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData();
  }

  fetchData() {
    return stores.MailConfiguration.fetchSmsData()
      .then((response) => {
        this.response = response.data;
        console.log(this.response);
        for (const index in this.response) {
          if (this.response[index].key === 'sms_driver') {
            this.smsDriver = this.response[index];
          } else if (this.response[index].key === 'sms_name') {
            this.smsName = this.response[index];
          } else if (this.response[index].key === 'sms_key') {
            this.smsKey = this.response[index];
          } else if (this.response[index].key === 'sms_secret') {
            this.smsSecret = this.response[index];
          }
        }
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /*
   *
   */
  onSubmit() {
    const settings = [];
    settings.push(this.smsDriver);
    settings.push(this.smsName);
    settings.push(this.smsKey);
    settings.push(this.smsSecret);
    return stores.MailConfiguration.update(settings)
      .then((response) => {
        if (response.responseCode === 200) {
          // @ts-ignore
          showToaster('success', 'SMS Configuration', 'successfully updated');
        } else {
          // @ts-ignore
          showToaster('danger', 'SMS Configuration', 'Fail to update');
        }
      }).catch((e: any) => {
        console.log(e);
      });
  }
}
