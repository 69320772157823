
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import vPagination from '@/components/VuePlainPagination.vue';
import Axios from 'axios';
import NevronHeader from '@/components/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import Edit from '@/modules/Epgs/Edit.vue';

@Component({
  components: {
      Skeleton,
      NevronHeader,
      NevronSearch,
      Edit,
  },
})

export default class Messages extends Vue {
  messages = stores.Messages;

  search: any = {
    query: '',
    current: '',
  };

  indexList: number[] = [];
  allChecked: boolean = false;

  mounted() {
    this.fetchMessages();
  }

  fetchMessages() {
    this.messages.getMessages()
    .then(( response: any ) => {
        this.messages = response;
    });
  }

   selectEdit(source: IEpgSource, e: any) {
    e.preventDefault();
    // this.selectedSource = source;
    // @ts-ignore
    this.$refs.edit.$children[0].open();
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = [];
      // @ts-ignore
      for (const iterator of this.epgSource) {
        if (iterator.id) {
          this.indexList.push(iterator.id);
        }
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
    /* if (this.indexList.length === this.epgSource.length) {
       this.allChecked = true;
    } else {
       this.allChecked = false;
    } */
  }

  saveEmited(epgSource: IEpgSource) {
   /*  this.epg.updateEpgSource(epgSource)
    .then((response) => {
      // @ts-ignore
      this.$refs.edit.$children[0].close();
      this.fetchEpgSource();
    }); */
    return true;
  }

  deleteSource(epgSource: IEpgSource) {
    /* this.epg.deleteEpgSource(epgSource)
    .then((response) => {
      // @ts-ignore
      this.$refs.edit.$children[0].close();
      this.fetchEpgSource();
    }); */
    return true;
  }

}
