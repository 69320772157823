
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import LanguageDropDown from '../../components/LanguageDropDown.vue';
import TheAdmin from '../../stores/TheLogedInAdmin';
// @ts-ignore
import i18n from '@/i18n';
// @ts-ignore
import CKEditor from 'ckeditor4-vue';
@Component({
  components: {
    SweetModal,
    LanguageDropDown,
    ckeditor: CKEditor.component,

  },
  mixins: [ Clickaway ],
})

export default class AttachUserTo extends Vue {
  @Prop()
    referenceText!: any;
  @Prop()
    referenceKey!: any;
  @Prop()
    textboxType!: any;

  userLanguage: ILanguage | null = null;
  selectedLanguage: ILanguage | null = null;

  translationValue = '';
  languages: ILanguage[] = [];
  targetAvailable: boolean = false;

  mounted() {
      this.$watch('$refs.modal.is_open', (oldState, newState) => {
        if (oldState === true && newState === false && this.languages.length === 0) {
        stores.Language.activeLanguages()
          .then((languages: any) => {
            stores.admin.me()
              .then((res) => {
                // @ts-ignore
                this.userLanguage = (res.language) ? res.language : this.languages.find((x) => x.language === i18n.locale);
                this.languages = languages;
                if (this.languages.length > 0) {
                  this.selectedLanguage = this.languages[0];
                }
              });
          });
        }
      });

  }
  @Watch('selectedLanguage')
  changeLangauge(language: ILanguage) {
    this.selectedLanguage = language;
    stores.Language.checkKey(this.referenceKey, this.selectedLanguage.id)
      .then((response: any) => {
        if (response && response.value) {
          this.translationValue = response.value;
        } else {
          this.translationValue = '';
        }
      });
  }
  save() {
    if (!this.selectedLanguage) {
      this.$swal('Alert!', 'Something Went Wrong!', 'error');
      return;
    }
    const translation = {
      key: this.referenceKey,
      languageId: this.selectedLanguage.id,
      value: this.translationValue,
      static: false,
    };
    stores.Language.createTranslation(translation)
      .then((response: any) => {
        if  (response && response.id) {
          this.$swal('Done!', 'Translation Successfully Created!', 'success');
        } else {
          this.$swal('Alert!', 'Something Went Wrong!', 'error');
        }
        this.cancel();
      }).catch((e: any) => {
          this.$swal('Alert!', 'Something Went Wrong!', 'error');
          this.cancel();
      });

  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
