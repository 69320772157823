
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {modulesMetaData} from '@/helpers/constants';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/ListNevronComponents/EmptyNevronHeader.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import {exportData, importData, translatedDisplayName} from '@/helpers/functions';
// @ts-ignore
import EventBus from '../../EventBus';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';

@Component({
  components: {
    ListNevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    PerPageDropdown,
    vPagination,
    TableGeneric,
    DynamicContent,
  },
})

export default class IndexGeneric extends Vue {
  @Prop()
  instance: any;
  response: any = null;
  items: IItem[] = [];

  selectedArray: number[] = [];
  allChecked = false;
  resetSelected = false;

  loading = true;
  file: any = '';
  uploadResponse: any = '';
  firstLoad = true;
  perpage = 20;

  search: any = {
    query: '',
    current: '',
  };

  createComponent: any = null;
  showComponent: any = null;
  selectedItem: IItem | null = null;

  localKey: any = '';
  localText: any = '';
  referenceKey: any = '';
  index: any = '';
  list: any = '';
  timer: any = 0;
  searchItems = _.debounce(() => {
    this.fetchData(1);
  }, 400); // fetches data with debounce (ms)

  get pageTitle() {
    if (this.instance.name) {
      return this.instance.name;
    } else {
      return this.instanceMeta.displayName;
    }
  }

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get fields() {
    return this.instanceMeta.fields;
  }

  mounted() {
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }
    // set perpage from URL
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }

    this.moduleChanged();

    // @ts-ignore
    this.$bus.$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      this.referenceKey = referenceKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });

  }

  @Watch('instance')
  moduleChanged() {
    this.index = (this.firstLoad && this.$route.query.page) ? Number(this.$route.query.page) : 1;
    this.perpage = 20;
    this.search.current = '';
    this.firstLoad = true;
    this.fetchData(this.index);
    if (!this.instanceMeta.dynamicModule) {
      Vue.prototype.WebsiteTitle.setModuleName(this.pageTitle);
    }
    if (this.instanceMeta.createComponent) {
      this.createComponent = () => import(`@/${this.instanceMeta.createComponent}.vue`);
    } else {
      this.createComponent = null;
    }
    if (this.instanceMeta.showComponent) {
      this.showComponent = () => import(`@/${this.instanceMeta.showComponent}.vue`);
    } else {
      this.showComponent = null;
    }
  }

  fetchData(index: number | null) {
    if (this.firstLoad) {
      this.loading = true;
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      console.log('tes0');

      return this.instance.fetchData(index, this.search.query, this.perpage)
        .then((response: any) => {
          this.response = response;
          this.items = this.response.data;
          this.search.current = this.search.query;

          if (!response) {
            showToaster('danger', '', 'Failed to fetch Data', response.code);
          }

          if (this.items.length === 0 && this.response.currentPage > 1) {
            this.fetchData(this.response.currentPage - 1);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.firstLoad = false;
        });
    }, 500);

  }

  deleteSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    const ids = this.selectedArray.map((el: any) => el.id);
    this.instance.deleteItems(ids)
      .then((response: any) => {
        this.refresh();
        this.resetSelected = !this.resetSelected;
        if (response.responseCode && !response.success) {
          const error = response.error.message;
          showToaster('danger', 'Error in deleting Multiple Items', Vue.prototype.translate(error));
          return;
        }
        showToaster('success', '', Vue.prototype.translate('successfully-deleted'));
        if (this.instance.routeName === 'modules') {
          // @ts-ignore
          this.$router.go();
        }
      });
  }

  openItem(item: IItem) {
    if (this.showComponent) {
      this.selectedItem = item;
      // @ts-ignore
      this.$refs.show.$children[0].open();
      return;
    }
    const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
    this.$router.push({
      name: `${this.instance.routeName}.${showPath}`,
      params: {id: String(item.id)},
      query: {lastpage: String(this.response.currentPage), perpage: String(this.perpage)},
    });
  }

  isItemLink(fieldIndex: number) {
    const hasImage = this.fields[0].type === 'image';
    return (!hasImage && fieldIndex === 0) || (hasImage && fieldIndex === 1);
  }

  deleteItem(item: any) {
    this.instance.deleteItem(item.id).then((response: any) => {
      this.refresh();
      if (response.responseCode && !response.success) {
        const error = response.error.message;
        showToaster('danger', translatedDisplayName(item), Vue.prototype.translate(error));
        return;
      }
      showToaster('success', translatedDisplayName(item), Vue.prototype.translate('successfully-deleted'));

      if (this.instance.routeName === 'modules') {
        // @ts-ignore
        this.$router.go();
      }
    });
  }

  updateRadioValue(item: IItem, fieldName: string) {
    const data = {id: item.id};
    // @ts-ignore
    data[fieldName] = true;
    this.instance.updateItem(item.id, data);
  }

  refresh() {
    this.fetchData(this.index);
  }

  redirect(value: any) {
    if (this.instance.routeName === 'clock' || this.instance.routeName === 'locations') {
      this.refresh();
    } else {
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: {id: String(value)},
        query: {lastpage: String(this.response.currentPage), perpage: String(this.perpage)},
      });
    }
  }

  @Watch('response.currentPage')
  pageChange() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.search.query = this.search.current;
    this.fetchData(this.response.currentPage);
  }

  exportSelected() {
    // @ts-ignore
    const ids = this.selectedArray.map((item) => item.id);
    exportData(this.instanceMeta.tableName, ids).then((response) => {
      if (response.result === true) {
        const csv = response.data;
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'Nevron-' + this.instance.routeName + '.csv';
        anchor.click();
      }
    });
  }

  @Watch('uploadResponse')
  importCsv() {
    importData(this.uploadResponse, this.instanceMeta.tableName)
      .then((response) => {
        if (response.result === true) {
          console.log(response.message);
          this.refresh();
        } else {
          console.log(response.message);
        }
      });
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

  get shouldHideDelete() {
    return !!this.instanceMeta.options?.hideDelete;
  }

}
