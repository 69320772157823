
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {SweetModal} from 'sweet-modal-vue';
    import axiosInstance from '../../helpers/axios';
    import session from '@/stores/Session';
    import stores from '@/stores';

    @Component({
        components: {
            SweetModal,
        },
    })
    export default class Categories extends Vue {
        option: any = 'collection';
        ratio: string = 'one';
        services: [] = [];

        mounted() {
            stores.modules.modules().then((response) => {
                    this.services = response;
                });
        }

        cancel() {
            // @ts-ignore
            this.$children[0].close();
            this.$emit('close', this.option);
        }

        update(option: string) {
            this.option = option;
        }

        updateRatio(option: string) {
            this.ratio = option;
        }

        isSelected(ration: string) {
            return this.ratio === ration;
        }

        next() {
            this.$emit('next', this.option);
            // @ts-ignore
            this.$children[0].close();
        }
    }
