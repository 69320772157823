export const dynamicModules = ['VOD', 'SERIES', 'TV_RADIO', 'CATALOGUE', 'SERVICE', 'APPLICATION'];

export enum ModuleTypePathMap {
  'APPLICATION' = 'applications',
  'TV_RADIO' = 'tv',
  'SERIES' = 'series',
  'VOD' = 'vod',
  'CATALOGUE' = 'catalogue',
  'SERVICE' = 'service',
}

export const modulesMetaData = {

  customers: {
    displayName: 'customers',
    createComponent: 'modules/Customers/Create',
    tableName: 'customers',
    fields: [
      {
        displayName: 'name',
        type: 'dual',
        name: 'firstName',
        backupName: 'lastName',
        emptyError: 'no-name-defined',
      },
    ],
    modalAttachToUser: {
      displayName: 'link-customer',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'name',
          type: 'dual',
          name: 'firstName',
          backupName: 'lastName',
          emptyError: 'no-name-defined',
        },
      ],
    },
    tableSectionStays: {
      fields: [
        {
          displayName: 'name',
          type: 'dual',
          name: 'firstName',
          backupName: 'lastName',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'main-customer',
          type: 'radio',
          name: 'mainCustomer',
        },
      ],
    },
    tableCreateStay: {
      selectOne: true,
    },
  },

  interests: {
    displayName: 'interests',
    createComponent: 'modules/Interests/Create',
    fields: [
      {
        displayName: '',
        type: 'image',
        name: 'image',
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  guestflowmodule: {
    displayName: 'guest-flow-modules',
    tableName: 'guest_flow_modules',
    fields: [
      {
        displayName: '',
        name: 'image',
        type: 'image',
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    options: {
      hideDelete: true,
    },
  },

  stays: {
    displayName: 'stays',
    createComponent: 'modules/Stays/Create',
    tableName: 'stays',
    noActive: true,
    fields: [
      {
        displayName: 'main-customer',
        type: 'object',
        name: 'mainCustomer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'main-customer-not-set',
      },
      {
        displayName: 'number-of-customers',
        notTranslatable: true,
        name: 'customersCount',
      },
      {
        displayName: 'check-in-date',
        type: 'date',
        name: 'checkinAt',
        emptyError: 'date-not-set',
      },
      {
        displayName: 'check-out-date',
        type: 'date',
        name: 'checkoutAt',
        emptyError: 'date-not-set',
      },
      {
        displayName: 'status',
        name: 'status',
      },
    ],
    // table meta for show pages
    tableSection: {
      noActive: true,
      fields: [
        {
          displayName: 'main-customer',
          type: 'object',
          name: 'mainCustomer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'main-customer-not-set',
        },
        {
          displayName: 'number-of-customers',
          notTranslatable: true,
          name: 'customersCount',
        },
        {
          displayName: 'check-in-date',
          type: 'date',
          name: 'checkinAt',
          emptyError: 'date-not-set',
        },
        {
          displayName: 'check-out-date',
          type: 'date',
          name: 'checkoutAt',
          emptyError: 'date-not-set',
        },
        {
          displayName: 'status',
          name: 'status',
        },
      ],
    },
  },

  stayTypes: {
    displayName: 'stay-types',
    createComponent: 'modules/Stays/Types/Create',
    tableName: 'stay_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  promotions: {
    displayName: 'promotions',
    createComponent: 'modules/Promotions/Create',
    tableName: 'promotions',
    fields: [
      {
        displayName: '',
        name: 'image',
        type: 'image',
        subFields: ['image'],
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'valid-to',
        name: 'validTo',
        emptyError: 'no-date-defined',
        notTranslatable: true,
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: '',
          name: 'image',
          type: 'image',
          subFields: ['image'],
        },
        {
          displayName: 'name',
          name: 'name',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'valid-to',
          name: 'validTo',
          emptyError: 'no-date-defined',
          notTranslatable: true,
        },
      ],
    },

  },

  assets: {
    displayName: 'assets',
    createComponent: 'modules/PropertyAssets/Assets/Create',
    tableName: 'property_assets',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  assetCategory: {
    displayName: 'asset-categories',
    createComponent: 'modules/PropertyAssets/Assets/Category/Create',
    tableName: 'asset_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  contactModule: {
    displayName: 'contact-module',
    createComponent: 'modules/ContactModule/Create',
    tableName: 'contacts',
    fields: [
      {
        displayName: 'contact-name',
        name: 'name',
        emptyError: 'No Name Defined',
      },
      {
        displayName: 'contact-number',
        name: 'number',
        emptyError: 'No Name Defined',
      },
    ],
  },

  zones: {
    displayName: 'locations',
    createComponent: 'modules/PropertyAssets/Locations/Zones/Create',
    tableName: 'locations',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    modalAttachToDevice: {
      displayName: 'link-location',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  zoneCategory: {
    displayName: 'location-categories',
    createComponent: 'modules/PropertyAssets/Locations/Category/Create',
    tableName: 'location_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  library: {
    displayName: 'accommodation-library',
    createComponent: 'modules/PropertyAssets/Library/Create',
    tableName: 'accommodation_libraries',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  ratingPlatform: {
    displayName: 'rating-platform',
    createComponent: 'modules/PropertyAssets/Media/RatingPlateform/Create',
    tableName: 'rating_platforms',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  commentProposal: {
    displayName: 'comment-proposals',
    createComponent: 'modules/PropertyAssets/Media/CommentProposals/Create',
    tableName: 'comment_proposals',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  socialMedia: {
    displayName: 'social-media',
    createComponent: 'modules/PropertyAssets/Media/SocialMedia/Create',
    tableName: 'social_media_platforms',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  stbDashboards: {
    displayName: 'stb-dashboards',
    createComponent: 'modules/Dashboards/Create',
    tableName: 'dashboards',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
    ],
    modalAttach: {
      selectOne: true,
      displayName: 'link-stb-dashboard',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },

  },

  mobileDashboards: {
    displayName: 'mobile-dashboards',
    createComponent: 'modules/Dashboards/Create',
    tableName: 'dashboards',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
    ],
    modalAttach: {
      selectOne: true,
      displayName: 'link-mobile-dashboard',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },
  gfMobileDashboard: {
    displayName: 'gf-mobile-dashboards',
    tableName: 'dashboards',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  modules: {
    displayName: 'modules',
    createComponent: 'modules/Modules/Create',
    tableName: 'modules',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'type', name: 'type', notTranslatable: 'true'},
      {displayName: 'id', name: 'id', notTranslatable: 'true'},
    ],
    attachModalPages: {
      displayName: 'link-catalog-modules',
      optionalParams: 'type=CATALOGUE',
    },
    attachModalService: {
      displayName: 'link-service-modules',
      optionalParams: 'type=SERVICE',
    },
  },

  accounts: {
    displayName: 'accounts',
    createComponent: 'modules/Accounts/Create',
    tableName: 'accounts',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'customer',
        type: 'object',
        name: 'customer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'no-customer-defined',
        route: 'customers.show',
      },
    ],
    modalAttach: {
      displayName: 'link-accounts',
      selectOne: false, // when only one instance is needed (radio instead of checkbox)
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
        {
          displayName: 'customer',
          type: 'object',
          name: 'customer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'no-customer-defined',
          route: 'customers.show',
        },
      ],
    },
    modalAttachToDevice: {
      displayName: 'link-accounts',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  users: {
    displayName: 'users',
    createComponent: 'modules/Users/Create',
    tableName: 'users',
    fields: [
      {
        displayName: 'username',
        name: 'username',
        notTranslatable: true,
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'customer',
        type: 'object',
        name: 'customer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'no-customer-defined',
        route: 'customers.show',
      },
      {
        displayName: 'email',
        name: 'email',
        notTranslatable: true,
        emptyError: 'no-email-defined',
      },
    ],
    modalAttach: {
      displayName: 'link-users',
      selectOne: false, // when only one instance is needed (radio instead of checkbox)
      fields: [
        {displayName: 'name', name: 'username', emptyError: 'No Name Defined'},
        {
          displayName: 'customer',
          type: 'object',
          name: 'customer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'No Customer Defined',
          route: 'customers.show',
        },
      ],
    },
    modalAttachToCustomer: {
      displayName: 'link-users',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'username',
          name: 'username',
          notTranslatable: true,
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'email',
          name: 'email',
          notTranslatable: true,
          emptyError: 'no-email-defined',
        },
      ],
    },
  },

  devices: {
    displayName: 'devices',
    createComponent: 'modules/Devices/Create',
    tableName: 'devices',
    fields: [
      {
        displayName: 'mac-address',
        name: 'macEth',
        backupName: 'macWifi',
        emptyError: 'no-mac-address-defined',
        notTranslatable: true,
      },
      {displayName: 'location', name: 'location', subFields: ['name'], route: 'zones.show', emptyError: 'no-location-defined'},
      {
        displayName: 'type',
        type: 'object',
        name: 'deviceType',
        subFields: ['name'],
        route: 'devices.types.show',
      },
    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'mac-address',
          name: 'macEth',
          backupName: 'macWifi',
          emptyError: 'no-mac-address-defined',
          notTranslatable: true,
        },
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
        {
          displayName: 'type',
          type: 'object',
          name: 'deviceType',
          subFields: ['name'],
          route: 'devices.types.show',
        },
      ],
    },
  },

  packages: {
    displayName: 'packages',
    createComponent: 'modules/Packages/Create',
    tableName: 'packages',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
    ],
    modalAttach: {
      displayName: 'link-package-items',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  languages: {
    displayName: 'languages',
    createComponent: 'modules/Languages/Create',
    tableName: 'languages',
    type: 'language',
    fields: [
      {displayName: 'flag', type: 'image'},
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true},
      {displayName: 'locale-code', name: 'localCode', notTranslatable: true},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    modalAttach: {
      displayName: 'link-languages',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'flag', type: 'image'},
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true},
        {displayName: 'locale-code', name: 'localCode', notTranslatable: true},
        {displayName: 'sort', name: 'sort', type: 'sort'},
      ],
    },
  },

  clock: {
    createComponent: 'modules/Time/Create',
    showComponent: 'modules/Time/Edit',
    tableName: 'world_clock_locations',
    fields: [
      {displayName: 'city', name: 'city'},
      {displayName: 'timezone', name: 'timeZone', notTranslatable: true},
    ],
  },

  locations: {
    createComponent: 'modules/Weather/Create',
    showComponent: 'modules/Weather/Edit',
    tableName: 'weather_locations',
    fields: [
      {displayName: 'city', name: 'city'},
      {displayName: 'country', name: 'country', notTranslatable: true},
      {displayName: 'latitude', name: 'latitude', emptyError: 'N/A', notTranslatable: true},
      {displayName: 'longitude', name: 'longitude', emptyError: 'N/A', notTranslatable: true},
    ],
  },

  tv: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'tv_channels',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'channel-number', name: 'channelNumber', type: 'sort'},
    ],
  },

  vod: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'vod_items',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
  },

  series: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'series',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'director', name: 'DIRECTOR'},
            {displayName: 'writer', name: 'WRITER'},
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'actor', name: 'ACTOR'},
          ],
        },
      ],
    },
  },

  applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'type', name: 'type'},
    ],
  },

  catalogue: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'catalogue_items',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    categories: {
      fields: [
        {displayName: '', type: 'image'},
        {displayName: 'name', name: 'name'},
        {
          displayName: 'sort',
          type: 'object',
          name: 'pivot',
          subFields: ['sort'],
        },
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
        {displayName: 'name', type: 'nevron-input', name: 'name'},
        {displayName: 'link-action-label', type: 'nevron-input', name: 'action_label'},
        {displayName: 'sort', type: 'number', name: 'sort'},
        {displayName: 'active', type: 'checkbox', name: 'active'},
      ],
    },
  },
  service: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'catalogue_items',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    categories: {
      fields: [
        {displayName: '', type: 'image'},
        {displayName: 'name', name: 'name'},
        {
          displayName: 'sort',
          type: 'object',
          name: 'pivot',
          subFields: ['sort'],
        },
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
        {displayName: 'name', type: 'nevron-input', name: 'name'},
        {displayName: 'link-action-label', type: 'nevron-input', name: 'action_label'},
        {displayName: 'sort', type: 'number', name: 'sort'},
        {displayName: 'active', type: 'checkbox', name: 'active'},
      ],
    },
  },

  accessories: {
    displayName: 'accessory',
    createComponent: 'modules/Accessories/Create',
    fields: [
      {
        displayName: 'Name',
        type: 'nevron-input',
        name: 'name',
        emptyError: 'No Name Defined',
      },
      {
        displayName: 'price',
        type: 'number',
        name: 'price',
      },
      {
        displayName: 'countable',
        type: 'checkbox',
        name: 'countable',
      },
      {
        displayName: 'active',
        type: 'checkbox',
        name: 'active',
      },
    ],
    attachAccessories: {
      optionalParams: 'moduleId',
      displayName: 'link-accessories',
    },
    accessoriesEdit: {
      displayName: 'edit-accessories',
      fields: [
        {
          displayName: 'name',
          type: 'nevron-input',
          name: 'name',
          emptyError: 'No Name Defined',
        },
        {
          displayName: 'price',
          type: 'number',
          name: 'price',
        },
        {
          displayName: 'countable',
          type: 'checkbox',
          name: 'countable',
        },
        {
          displayName: 'active',
          type: 'checkbox',
          name: 'active',
        },
      ],
    },
  },
};
