
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
// @ts-ignore
import draggable from 'vuedraggable';
import virtualList from 'vue-virtual-scroll-list';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import {sortCheck, sortMoved} from '@/helpers/VueDraggableResorting';
import CreateCategory from './CreateBasic.vue';
import _ from 'lodash';
import {exportData, importData, translatedDisplayName} from '@/helpers/functions';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';

@Component({
  components: {
    draggable,
    virtualList,
    ListNevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    CreateCategory,
    NevronSearchNoResults,
    NevronClickConfirm,
  },
})

export default class CategoriesList extends Vue {
  @Prop()
  module!: IModule;
  @Prop()
  tableName!: string;
  hasRemove: boolean = true;
  checkPopup: boolean = true;

  response: any = null;
  items: ICategories[] = [];

  search: any = {
    query: '',
    current: '',
  };
  file: any = '';
  uploadResponse: any = '';
  sort: any = {
    moved: [],
    changed: [],
    element: 0,
  };

  indexList: number[] = [];
  allChecked: boolean = false;
  showMain: boolean = true;
  selected: IBulkResponse | null = null;
  searchItems = _.debounce(() => {
    this.fetchData();
  }, 400);

  get itemIds(): number[] {
    return this.items.map((item: any) => item.id);
  }

  sortMoved(e: any) {
    console.log(2);
    return sortMoved(e, this.sort);
  }

  sortCheck(id: number | null, type: string): boolean {
    return sortCheck(id, type, this.sort);
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
      this.allChecked = false;
    }
  }

  /* when item is checked */
  updateCheck() {
    if (this.itemIds.every((id: number) => this.indexList.includes(id))) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  fetchData() {
    return this.module.getAllCategories!(this.search.query)
      .then((response) => {
        this.response = response;
        this.items = response.data;
        this.search.current = this.search.query;
        this.updateCheck();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setPageName('categories');
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }
    this.fetchData();
  }

  refresh() {
    this.fetchData();
  }

  deleteCategory(category: any) {
    this.module.deleteCategory!(Number(category.id)).then(() => this.fetchData());
  }

  exportSelected() {
    exportData(this.tableName, this.indexList).then((response) => {
      if (response.result === true) {
        const csv = response.data;
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'Nevron-' + this.tableName + '.csv';
        anchor.click();
      }
    });
  }

  @Watch('uploadResponse')
  importCsv() {
    importData(this.uploadResponse, this.tableName)
      .then((response) => {
        if (response.result === true) {
          console.log(response.message);
          this.refresh();
        } else {
          console.log(response.message);
        }
      });
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

}
