
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import axiosInstance from '../../../helpers/axios';
import session from '@/stores/Session';
import { SweetModal } from 'sweet-modal-vue';
@Component({
  components: {
    SweetModal,
  },
})

export default class CreateProfile extends Vue {
  newProfile: IProfile = {
    id: null,
    imageId: '',
    name: '',
    pin: '0000',
    pinRequired: false,
    isAdult: false,
    active: true,
    accountId: null,
    dashboardId: null,
  };
  imageUrl: string = '';
  attachableContent: any = null;
  dragOver: boolean = false;
  preLeave: boolean = false;

  mounted() {
    const form = document.getElementById('create-form-profile')! as HTMLFormElement;
    const validation = form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.onSubmit();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
  }

  onSubmit() {
    if (this.$route.params.id) {
      this.newProfile.accountId = Number(this.$route.params.id);
    }
    return stores.accounts.createAccountProfile(this.newProfile)
      .then((response) => {
        this.cancel();
        this.$router.push({name: 'accounts.profile.show',
          params: {acc: this.$route.params.id, id: response.id}});
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   * Resets the values to their defaults and then reroutes back to the owning account
   */
  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }
}
