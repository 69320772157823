
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import NevronInput from '@/components/NevronInput.vue';
import axiosInstance from '@/helpers/axios';
import session from '@/stores/Session';
import Skeleton from '@/modules/Skeleton.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import CreateCategory from './CreateMulti.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import router from '@/router';

@Component({
  components: {
    virtualList,
    NevronHeader,
    NevronInput,
    Skeleton,
    NevronFooter,
    NevronSearchNoResults,
    CreateCategory,
    NevronImageField,
    TableModalGeneric,
    TableGeneric,
    NevronAdvanceTextBox,
  },
})
export default class ShowMulti extends Vue {
  @Prop()
  module!: IModule;

  response: any = null;
  category: ICategoriesMulti | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;

  source: any;
  fullResponse: any = null;

  get categoryName() {
    if (!this.category?.parentId) {
      return Vue.prototype.translate(this.module.name) + ' ' + Vue.prototype.translate('all');
    }
    return Vue.prototype.translate(this.category?.name);
  }

  mounted() {
    this.refresh(this.$route.params.id);
  }

  refresh(id: number | string) {
    return this.module.getSpecificCategory!(Number(id) || 0)
      .then((response) => {
        this.response = response;
        this.category = response.data;
        if (parseInt(this.$route.params.id, 10) === 0) {
          if (this.category === null) {
            router.go(0);
          } else {
            if (Vue.prototype.translate(this.category.name) === '') {
              this.category.name = 'all';
            }
          }
        }
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(this.$route.params.id);
  }

  onSubmit() {
    if (this.category) {
      return this.module.updateCategory!(this.category.id!, this.category)
        .then(async (response) => {
          if (response.responseCode === 200) {
            await this.refresh(Number(this.$route.params.id));
            // @ts-ignore
            showToaster('success', Vue.prototype.translate(this.category.name), 'successfully updated');
          } else {
            // @ts-ignore
            showToaster('danger', Vue.prototype.translate(this.category.name), 'Fail to update');
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  imageSelected(image: IMediaItem) {
    if (this.category) {
      this.category.imageId = (image) ? image.id : null;
    }
  }

  addItemsToCategory(newItemsList: any) {
    if (this.category && this.category.subitems) {
      const subitemIds = this.category.subitems.map((item: any) => item.id);
      const uniqueNewItemsList = newItemsList.filter((item: any) => !subitemIds.includes(item.id));
      this.category.subitems = this.category.subitems.concat(uniqueNewItemsList);
    }
  }

  deleteCategory(cat: ICatalogueCategory) {
    return this.module.deleteCategory!(cat.id!)
      .then((response: any) => {
        if (cat.id === this.category?.id) {
          this.$router.push({
            name: `${this.module.path}.categories`,
            params: {
              moduleId: String(this.module.id),
              id: String(this.category.parentId),
            },
          });
        } else {
          this.refresh(this.$route.params.id);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  detachItem(item: IItem) {
    this.category!.subitems = this.category!.subitems!.filter((el: any) => el !== item);
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
      this.allChecked = false;
    }
  }

  updateCheck() {
    if (this.itemIds.every((id: number) => this.indexList.includes(id))) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  get itemIds(): number[] {
    return (this.category as ICategories).children!.map((item: IItem) => item.id);
  }

  getItemsForModule() {
    // @ts-ignore
    this.$refs.attach.$children[0].open();
  }

  openItem(item: IItem) {
    this.$router.push({name: `${this.module.path}.items.show`, params: {id: String(item.id)}});
  }

  openCategory(category: ICategories) {
    this.$router.push({name: `${this.module.path}.categories`, params: {id: String(category.id)}});
  }

}
