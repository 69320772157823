  /*
   * Sort Change
   */
  function sortChange(id: number | null, type: string, sort: any) {
    if (type === 'moved') {
      if (!sort.moved.includes(id)) {
        sort.moved.push(id);
        if (sort.changed.includes(id)) {
          delete sort.changed[sort.changed.indexOf(id)];
        }
      }
    } else if (type === 'changed') {
      if (!sort.changed.includes(id) && !sort.moved.includes(id)) {
        sort.changed.push(id);
      }
    }
  }

  /*
   * Sort Resort
   */
  function sortResort(start: number, items: any[], sort: any, key: any) {
    for (let index = start; index < items.length; index++) {
      // change position
      items[index][key] = items[index][key] + 1;

      // save to changed array
      sortChange(items[index].id, 'changed', sort);

      // break if necessary
      if (!(index + 1 in items) || items[index][key] !== items[index + 1][key]) {
        break;
      }
    }
  }

  /*
   * Sort Moved
   * save moved element to to sort.element
   */
  export function sortMoved(evt: any, sort: any) {
    return sort.element = evt.draggedContext.futureIndex; // existing Element - tisti, ki je bil prej na tej poziciji
  }

  /*
   * Sort Move
   */
  export function sortMove(items: any[], sort: any, key: any) {
    sortChange(items[sort.element].id, 'moved', sort);

    if (sort.element === 0) {
      // move to 1st place
      items[sort.element][key] = 1;

      // resort only if element with channel number 1 exists
      if (items[1][key] === 1) {
        sortResort(sort.element + 1, items, sort, key);
      }
    } else if (sort.element - 1 in items && sort.element + 1 in items) {
      // move between elements
      const prev = items[sort.element - 1];
      const next = items[sort.element + 1];

      if (prev[key] + 1 === next[key]) {
        // moved element is between two elements in group
        items[sort.element][key] = prev[key] + 1;

        // resort next elements
        sortResort(sort.element + 1, items, sort, key);
      } else {
        // moved element is between two groups
        items[sort.element][key] = prev[key] + 1;
      }
    } else {
      // move to last place
      const prev = items[sort.element - 1];
      items[sort.element][key] = prev[key] + 1;
    }

    // save to backend
    // to je v resnici patch enega kanala z novo številko kanala
    // TODO

    return items[sort.element];
  }

  /*
   * Sort Check
   */
  export function sortCheck(id: number | null, type: string, sort: any): boolean {
    if (type === 'moved') {
      return sort.moved.includes(id);
    } else if (type === 'changed') {
      return sort.changed.includes(id);
    } else {
      return false;
    }
  }
