
import {Component, Vue, Prop} from 'vue-property-decorator';
import vPagination from '@/components/VuePlainPagination.vue';
import StreamModule from '@/stores/modules/Streams';
import EditHardware from '@/modules/Transcoder/EditHardware.vue';
import EditIn from '@/modules/Transcoder/EditIn.vue';
import EditDestination from '@/modules/Transcoder/EditDestination.vue';
import Edit from '@/modules/Transcoder/Edit.vue';
import AddDescription from '@/modules/Transcoder/AddDestination.vue';

@Component({
  components: {
    vPagination,
    EditHardware,
    EditIn,
    EditDestination,
    Edit,
    AddDescription,
  },
})
export default class TranscoderDetails extends Vue {
  @Prop()
  module!: StreamModule;

  stream: ITranscoderStream | null = null;
  receivedData: boolean = false;

  destinationIndex: number = -1;

  destinationEditClicked(index: number) {
    this.destinationIndex = index;
    // @ts-ignore
    this.$refs.destinationEdit.$children[0].open();
  }

  saveEdit(stream: ITranscoderStream) {
    this.module.saveEditedStream(stream)
      .then((response) => {
        console.log(('You have successfully edited an existing stream!'));
        this.stream = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteStream() {
    if (this.stream && this.stream.id) {
      if (window.confirm('Are you sure you want to remove this stream?')) {
        this.module.deleteStream(this.stream.id);
        this.$router.back();
      }
    }
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    console.log(this.module);

    return  this.module.getSelectedStream(Number(this.$route.params.id))
      .then((response) => {
        this.stream = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

}
