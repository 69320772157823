
import { Component, Vue } from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';

@Component({
  components: {
    NevronHeader,
  },
})
export default class CreateAdmin extends Vue {
  admin: any = {
    id: null,
    email: null,
    name: '',
    username: null,
    password: '',
    passwordConfirmation: '',
    status: 1,
  };
  error: string = '';

  nameRules = {};
  emailRules = {};
  usernameRules = {};
  passwordRules = {};
  statusRules = {};

  onSubmit() {
    this.error = '';
    stores.admins.create(this.admin)
      .then((response) => {
        console.log('response2', response);
        this.$router.push({name: 'admins'});
    }).catch((error) => {
      console.log('error2', error);
      this.error = error.errors;
    });
  }

  mounted() {
    stores.admins.getAdminValidationRules()
    .then((response) => {
      const validationRules = response;
      if (validationRules[`name`]) {
        this.nameRules = validationRules[`name`];
      }
      if (validationRules[`email`]) {
        this.emailRules = validationRules[`email`];
      }
      if (validationRules[`username`]) {
        this.usernameRules = validationRules[`username`];
      }
      if (validationRules[`password`]) {
        this.passwordRules = validationRules[`password`];
      }
      if (validationRules[`status`]) {
        this.statusRules = validationRules[`status`];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.onSubmit();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }
}
