
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import session from '@/stores/Session';
import axiosInstance from '@/helpers/axios';
import NevronInput from '@/components/NevronInput.vue';

import SeriesModule from '@/stores/modules/Series';
import CreateEpisodes from '../../../../modules/Series/List/Seasons/Episodes/create.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    virtualList,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    // ClassicEditor,
    MultiSelect,
    NevronInput,

    CreateEpisodes,
    NevronImageField,
    NevronAdvanceTextBox,
  },
})
export default class SeriesDetails extends Vue {
  @Prop()
  module!: SeriesModule;
  @Prop()
  seriesId!: any;

  parentTye: string = 'seasons';
  episodeType: string = '';

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  // @ts-ignore
  seasons: Iseasons = {
    id: null,
    active: true,
    sort: 0,
    name: '',
    shortDescription: '',
    fullDescription: '',
    image: null,
    imageId: null,
    year: null,
    price: '0',
    rating: '',
    numberOfEpisodes: 1,
    moduleId: null,
    seriesId: null,
  };
  categories: any[] = [];
  episodes: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  // editor: any = ClassicEditor;
  imageUrl: any = '';
  autocompleteCatItems: any[] = [];
  catTag: string = '';

  itemName: string = '';

  /**
   * Delete the Seasons from this series
   */
  deleteSeason() {
    return this.module.deleteSeason(this.seriesId, this.seasons?.id!)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  pushBack() {
    this.$router.push({
      name: 'series.show',
      params: {
        moduleId: this.$route.params.moduleId,
        id: this.$route.params.seriesId,
      },
    });
  }

  openTrailor() {
    this.episodeType = 'trailer';
    localStorage.setItem('episodeTypes', 'trailer');
    // @ts-ignore
    this.$refs.createEpisodes.$children[0].open();
  }

  detachTrailer(id: any, seriesId: number, e: any) {
    e.preventDefault();
    if (this.seasons && confirm('Do you really want to delete this Trailor?')) {
      return this.module.detachTrailer(this.parentTye, this.seasons.id, id)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  openEpisode() {
    this.episodeType = 'episode';
    localStorage.setItem('episodeTypes', 'episode');
    // @ts-ignore
    this.$refs.createEpisodes.$children[0].open();
  }

  attach(a: any) {
    // @ts-ignore
    this.Series.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  imageSelected(image: IMediaItem) {
    if (this.seasons) {
      this.seasons.imageId = (image) ? image.id : null;
    }
  }

  saveAndReload(obj: Iseasons) {
    console.log('season', obj);
    return this.saveSeasonsItems(obj)
      .then((response) => {
        this.seasons = response.data;
        console.log('seasons', Vue.prototype.translate(this.seasons.name));
        console.log('seasons', response);

        if (response.responseCode === 200) {
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.seasons.name), 'successfully updated');
        } else {
          // @ts-ignore
          showToaster('danger',  Vue.prototype.translate(this.seasons.name), 'Fail to update', response.code);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.log(error);
        showToaster('danger',  Vue.prototype.translate(this.seasons.name), 'Fail to update');
      });
  }

  saveSeasonsItems(seasons: Iseasons) {
    return axiosInstance.put(`series/${this.module.id}/series/${this.$route.params.id}/seasons/${this.seasons.id}`, seasons)
      .then((response) => response.data);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
    this.itemName = this.module.itemsName;
  }

  // @ts-ignore
  @Watch('$route.params.id', { immediate: true })
  loadPage() {
    this.getPage(Number(this.$route.params.id));
    this.contentUrl = '';
  }

  getPage(id: number) {

    return this.module.getSeasonsDetails(id, Number(this.$route.params.seriesId))
      .then((response) => {
        console.log(response);
        this.seasons = response;
      })
      .catch((error) => {
        console.log((error));
      });
  }

  getAutocompleteCategories(text: string = '') {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        return this.module.getAutocompleteCategories(this.searchQuery, this.source.token)
          .then((response) => {
            // console.log('auto complete response', response);
            this.categories = [];
            for (const index in response) {
              if (response[index]) {
                this.categories.push(Vue.prototype.translate(response[index].name));
              }
            }
            this.searchInProgress = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }, 500);
    } else {
      this.source.cancel();
    }
  }
}
