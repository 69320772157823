
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditImage extends Vue {
  @Prop()
    img!: IMediaItem;

  image: IMediaItem | null = JSON.parse(JSON.stringify(this.img)); // to je deep copy

  @Watch('img', { immediate: true })
    setModuleFromProps() {
      this.image = JSON.parse(JSON.stringify(this.img));
  }

  removeImage() {
    if (confirm('Do you really want to remove this image?')) {
      this.$emit('delete');
    }
  }

  save() {
    if (this.image) {
      return stores.File.updateImage(this.image)
        .then((response) => {
          console.log('You have successfully edited a device!');
          // @ts-ignore
          this.$parent.getImage(this.$route.params.fileId);
          // @ts-ignore
          this.$children[0].close();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  cancel(): void {
    this.image = null;
    // @ts-ignore
    this.$children[0].close();
  }
}
