
import {Component, Vue} from 'vue-property-decorator';
import stores from '../../stores';

@Component({
  components: {},
})

export default class DeleteDashboard extends Vue {
  /**
   * Fired when module is created
   */
  created() {
    this.delete();
  }

  /**
   * delete dashboard event
   */
  delete() {
    return stores.dashboards.deleteDashboard(this.$route.params.id)
    .then(() => {
        if (this.$route.params.type === 'mobile') {
            this.$router.push({name: 'mobileDashboards'});
        } else if (this.$route.params.type === 'GFMobile') {
            this.$router.push({name: 'gfMobileDashboards'});
        } else {
            this.$router.push({name: 'stbDashboards'});
        }
    })
    .catch((reason) => {
      console.log('Could not delete', reason);
    });
  }
}
