
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {
    SweetModal,
  },
  mixins: [ Clickaway ],
})

export default class AddKey extends Vue {
  @Prop()
    language: any;
  @Prop()
    phrase: any;

  reference: ITranslation = {
    key: '',
    languageId: null,
    value: '',
    id: null,
    static: true,
  };
  localLanguage: any = null;
  @Watch('phrase', {immediate: true})
  setLocalPhrase() {
    if (this.phrase) {
      this.reference = JSON.parse(JSON.stringify(this.phrase));
    } else {
      this.reference = {
        key: '',
        languageId: null,
        value: '',
        id: null,
        static: true,
      };
    }
  }
  @Watch('language', {immediate: true})
  setLocalLanguage() {
    console.log('language changed', this.language);
    this.localLanguage = JSON.parse(JSON.stringify(this.language));
    if (this.language) {
      this.reference.languageId = this.localLanguage.id;
    }
  }

  updateKey() {
    if (!this.localLanguage || (this.localLanguage && this.localLanguage.localCode === 'en_US')) {
      this.reference.key = this.reference.value ? this.reference.value.toLowerCase().replace(/\s+/g, '-') : '';
      console.log('key is updating', this.reference.key);
    }
  }
  save() {
    this.$emit('save', this.reference);
    this.cancel();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
