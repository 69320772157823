
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import CreateFolder from '@/modules/Media/CreateFolder.vue';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';
import {Setting} from '@/stores/Setting';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})
export default class EpgSetting extends Vue {
  timeout: ISetting = new Setting();
  isEnabled: ISetting = new Setting();
  reconnectStream: ISetting = new Setting();
  catalogMail: ISetting = new Setting();
  testMode: ISetting = {
    id: 0,
    key: 'test_mode',
    value: false,
  };
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('module-element-settings');
    this.fetch();
  }
  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'channel_timeout') {
        this.timeout = data[index];
      } else if (data[index].key === 'test_mode') {
        this.testMode = data[index];
        this.testMode.value = data[index].value;
      }  else if (data[index].key === 'is_channel_timeout') {
        this.isEnabled = data[index];
        this.isEnabled.value = this.isEnabled.value !== '0';
      } else if (data[index].key === 'reconnect_stream') {
          this.reconnectStream = data[index];
          this.reconnectStream.value = this.reconnectStream.value !== '0';
      } else if (data[index].key === 'catalog_default_mail') {
        this.catalogMail = data[index];
      }
    }
  }

  updateSetting(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        this.fetch();
        console.log('setting updated successfully..');
      });
  }
}
