import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/accessories';
// @ts-ignore
const moduleId: any;

export class Accessories extends Model<IAccessories> implements IAccessories {

  id!: number | null;
  moduleId!: number | null;
  name!: string;
  active!: boolean;
  sort!: number | null;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: null,
          moduleId: null,
          active: '',
          name: '',
          sort: '',
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }

}

export default class AccessoriesCollection extends Collection<Accessories> {

  routeName = 'accessories';

  model(): Constructor<Accessories> {
    return Accessories;
  }

  url(): string {
    return URL;
  }

  fetchData(index: number | null, search: string | null, perPage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }
    // @ts-ignore
    // tslint:disable-next-line:no-shadowed-variable
    const moduleId = localStorage.getItem('moduleId');

    if (search) {
      return axiosInstance.get(`${this.url()}/${moduleId}/list/?search=${search}&page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}/${moduleId}/list/?page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createNewItem(item: any) {
    return axiosInstance.post(`${this.url()}`, item)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: any, data: any) {
    return axiosInstance.put(`${this.url()}/${id}`, data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
