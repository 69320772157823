
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {SweetModal} from 'sweet-modal-vue';
    import axiosInstance from '../../helpers/axios';
    import session from '@/stores/Session';
    import stores from '@/stores';

    @Component({
        components: {
            SweetModal,
        },
    })
    export default class GuestFlow extends Vue {
        option: any = 'collection';
        @Prop()
        panel: any;
        guestFlow: [] = [];
        list: [] = [];
        data: any = {
          category: null,
          module: null,
          sortType: 'sort',
          ratio: 'image',
          quantity: 'all',
          data: [],
        };

        cancel() {
            // @ts-ignore
            this.$children[0].close();
            this.$emit('close', this.option);
            this.clearData();
        }

        mounted() {
          // @ts-ignore
          stores.GuestFlow.listofServices()
          .then((response) => {
            this.guestFlow = response.data;
          });
        }
        isSelected(ration: string) {
          return this.data.ratio === ration;
        }
        updateRatio(option: string) {
          this.data.ratio = option;
        }

        update(option: string) {
                this.option = option;
        }

        isValidServiceType(type: string) {
            // return type ===
            return true;
        }

        clearData() {
            this.option = null;
            // this.guestFlow = [];
            this.list = [];
        }

        next() {
            const temp: any[] = [];
            for (const index in this.list) {
                if (index) {
                    // @ts-ignore
                    temp.push(this.guestFlow[this.list[index]]);
                }

            }
            this.$emit('response', temp);
            // @ts-ignore
            this.$children[0].close();
        }
    }
