
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import AttachItem from '@/modules/Packages/Attachpackageitem.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import Axios from 'axios';
import collection from '@/stores/abstract/Collection.ts';
import session from '../../stores/Session';
import axiosInstance from '../../helpers/axios';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '@/components/NevronInput.vue';
import router from '@/router';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronImageField,
    NevronHeader,
    NevronFooter,
    NevronInput,
    vPagination,
    AttachItem,
    Skeleton,
  },
})

export default class PackageShow extends Vue {
  selectedPackage: IPackage | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  moduleItems: any[] = [];
  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;
  modules: any[] = [];
  dragOver: boolean = false;
  preLeave: boolean = false;
  stores = stores; // bring stores in template context

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(Number(this.$route.params.id));
  }

  save() {
    if (this.selectedPackage) {
      return stores.Package.update(this.selectedPackage.id, this.selectedPackage)
        .then(async (response) => {
          if (response.responseCode === 200) {
            await this.refresh(Number(this.$route.params.id));
            // @ts-ignore
            showToaster('success', Vue.prototype.translate(this.selectedPackage.name), 'successfully updated');
          } else {
            // @ts-ignore
            showToaster('danger', Vue.prototype.translate(this.selectedPackage.name), 'Fail to update', response.code);
          }
        })
        .catch((e) => {
          console.log(e);
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.selectedPackage.name), 'Fail to update');
        });
    }
  }

  onSubmitStatus() {
    if (this.selectedPackage) {
      return stores.Package.update(this.selectedPackage.id, {active: this.selectedPackage.active})
        .then(() => {
          console.log('success');
        }).catch((e: any) => {
          console.log(e);
        });
    }
  }

  imageSelected(image: IMediaItem) {
    if (this.selectedPackage) {
      this.selectedPackage.imageId = (image) ? image.id : null;
    }
  }

  removePackageItem(item: IPackageItem, index: number, e: any) {
    e.preventDefault();
    if (confirm('Do you really want to remove this package item?')) {
      return stores.PackageItems.deleteItem(Number(item.id))
        .then((response: any) => {
          console.log('Success!');
          if (this.selectedPackage) {
            this.selectedPackage.items.splice(index, 1);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  searchItemsToAttach(query: string) {
    // this.searchQuery = query;
    // this.CancelToken = Axios.CancelToken;
    // this.source = this.CancelToken.source();
    // console.log('query', this.searchQuery);
    // console.log('search requested');
    // if (!this.searchInProgress) {
    //   this.searchInProgress = true;
    //   setTimeout(() => {
    //     return stores.Package.search(this.searchQuery, this.fullResponse.currentPage, this.source.token)
    //       .then((response) => {
    //         console.log(this.searchQuery);
    //         this.fullResponse = response;
    //         if (this.searchQuery !== '') {
    //           this.$router.push({query: {query: this.searchQuery, page: String(this.fullResponse.currentPage)}});
    //         } else {
    //           this.$router.push({query: {page: String(this.fullResponse.currentPage)}});
    //         }
    //         console.log('search executed');
    //         this.searchInProgress = false;
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   }, 500);
    // } else {
    //   this.source.cancel();
    // }
  }

  async attachSaveEmited(indexMap: any) {
    for (const [moduleId, indexList] of Object.entries(indexMap)) {
      if ((indexList as []).length > 0) {
        const itemIds = (indexList as []).map((item: IItem) => item.id);
        // @ts-ignore
        await stores.PackageItems.attachItem(itemIds, this.selectedPackage.id, moduleId);

      }
    }
    this.refresh(this.selectedPackage!.id!);
    this.moduleItems = [];
  }

  getImages() {
    // @ts-ignore
    this.$refs.image.$children[0].open();
  }

  next(nextId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'packages.show', params: {id: nextId}});
    this.refresh(Number(nextId)); // TODO: FIX
  }

  previous(previousId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'packages.show', params: {id: previousId}});
    this.refresh(Number(previousId)); // TODO: FIX
  }

  saveEmited(newPackage: IPackage) {
    return stores.Package.updateItem(newPackage.id!, newPackage)
      .then((response: any) => {
        if (newPackage.id) {
          this.refresh(newPackage.id);
        }
        // @ts-ignore
        this.$refs.edit.$children[0].close();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  checkAll() {
    if (!this.allChecked && this.selectedPackage) {
      this.indexList = [];
      // @ts-ignore
      for (const iterator of this.selectedPackage.items) {
        if (iterator.id) {
          this.indexList.push(iterator.id);
        }
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {

    if (this.selectedPackage && this.indexList.length === this.selectedPackage.items.length) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  removePackage() {
    if (this.selectedPackage && this.selectedPackage.id) {
      return stores.Package.deleteItem(this.selectedPackage.id)
        .then((response: any) => {
          console.log('You have successfully deleted an account');
          // @ts-ignore
          this.$router.push({name: 'packages'});
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  removeAllItems() {
    if (confirm('Do you really want to remove this package items?')) {
      return stores.PackageItems.deleteAllItems(this.indexList)
        .then((response: any) => {
          console.log('Success!');
          this.refresh(Number(this.$route.params.id));
          this.indexList = [];
          this.allChecked = false;
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  refresh(id: number) {
    return stores.Package.getSelectedPackage(id)
      .then((response: any) => {
        this.selectedPackage = response;
        console.log('Package: ', this.selectedPackage);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  goToEpisode(epId: number, moduleId: number) {
    return axiosInstance.get(`episodes/${epId}`)
      .then((response) => {
        router.push(`/series/${moduleId}/series/${response.data.seriesId}/seasons/${response.data.id}/episodes/${epId}`);
      });
  }

  goToModule(id: number, moduleId: number) {
    return axiosInstance.get(`episodes/${id}`)
      .then((response) => {
        if (response.data.checkTable === 'Series') {
          router.push(`/series/${moduleId}/series/${response.data.seriesId}`);
        } else if (response.data.checkTable === 'Seasons') {
          router.push(`/series/${moduleId}/series/${response.data.seriesId}/seasons/${response.data.id}`);
        }
      });
  }

}
