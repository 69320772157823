
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import stores from '@/stores';
// @ts-ignore
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import { AxiosError } from 'axios';
import ModulesSkeleton from '@/modules/ModulesSkeleton.vue';
// @ts-ignore
import EventBus from './EventBus';
// @ts-ignore
import i18n from './i18n.js';
// @ts-ignore
import VueRangeSlider from 'vue-range-component';
import 'vue-range-component/dist/vue-range-slider.css';
// main.js
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

Vue.component('VueSlider', VueSlider);
Vue.component('VueRangeSlider', VueRangeSlider);
Vue.prototype.$bus = EventBus;
@Component({
  components: {
    HomeView,
    LoginView,
    ModulesSkeleton,
  },
})
export default class App extends Vue {
  admin = stores.admin;
  modulesLoaded = false;
  adminLoaded = false;
  settingsLoaded = false;

  get ready() {
    return this.modulesLoaded && this.adminLoaded && this.settingsLoaded;
  }

  @Watch('admin.token', { immediate: true })
  adminFetch(newToken: string, oldToken: string) {
    if (this.admin.token) {
      this.admin
        .fetch()
        .then(() => {
          document.documentElement.style.overflow = 'auto';

          // console.log('Admin fetcehd ok', JSON.parse(JSON.stringify(this.admin)));
        })
        .catch((reason) => {
          console.error('error fetching admin!', reason);
        });
    } else if (oldToken) {
      this.$router.push('/login');
    }
  }

  async created() {
    await stores.master.getProjects()
      .then((response) => {
        if (stores.session.project) {
          // @ts-ignore
          stores.session.project = response.find(
            (project: IManagerProject) => project.id === stores.session.project!.id,
          );
          Vue.prototype.WebsiteTitle.updateWebsiteTitle();
        }
        this.setFavicon();
      })
      .catch((error) => console.log(error));
  }

  mounted() {
    this.beforeLoad();
  }

  @Watch('admin.isLoggedIn')
  beforeLoad() {
    if (this.admin.isLoggedIn) {
      // fetch modules
      stores.modules.fetch().then((response) => {
        localStorage.setItem('menus', JSON.stringify(response));
        this.modulesLoaded = true;
      });
      // fetch admin data
      stores.admin.me().then((response) => {
        this.adminLoaded = true;
        if (response.language) {
          i18n.locale = response.language.localCode;
        }
      });
      // fetch translations
      stores.Language.loadTranslations();
      // fetch settings
      stores.Setting.getData().then((response) => {
        this.settingsLoaded = true;
      });
    }
  }

  setFavicon() {
    const browserFavicon = document.getElementById('favicon')! as HTMLAnchorElement;
    const project = stores.session.project;
    browserFavicon.href = (project && project.mobileLogo) ? project.mobileLogo : require('../assets/nevron_logo.png');
  }

}
