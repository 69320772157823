
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import stores from '../../stores';
import session from '@/stores/Session';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditPackage extends Vue {
  @Prop()
    pac!: IPackage;

  newPackage: IPackage | null = null;

  @Watch('pac', {immediate: true})
    setModuleFromProps() {
      this.newPackage = JSON.parse(JSON.stringify(this.pac));
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  openFolderSys() {
    this.$emit('openFileSys');
    // @ts-ignore
    this.$children[0].close();
  }

  getFilePath(): string {
      return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  removePackage() {
    if (confirm('Are you sure you want to remove this package?')) {
      this.$emit('delete', this.newPackage);
    }
  }

  save(): void {
     this.$emit('save', this.newPackage);
  }

  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }
}
