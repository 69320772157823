<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h1 class="display-2 fw-medium">
              4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4
            </h1>
            <h4 class="text-uppercase">{{ translate(errorMessage) }}</h4>
            <div class="mt-5 text-center">
              <button class="btn btn-primary" @click="$router.go(-2)">{{translate("go-back")}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="@/assets/images/error-img.png" alt class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        errorMessage: 'SORRY, PAGE NOT FOUND',
    };
  },
    mounted() {
      window.addEventListener('popstate', () => {
        this.$router.go(-2);
      });

      if (this.$route.query.error) {
          this.errorMessage = this.$route.query.error;
      }
    },
};
</script>

