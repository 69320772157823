import AccountDelete from './modules/Accounts/Delete.vue';
import Accounts from './modules/Accounts/Index.vue';
import AccountsCreate from './modules/Accounts/Create.vue';
import AccountsShow from './modules/Accounts/Show.vue';
import AddressCreate from './modules/Customers/Address/Create.vue';
import AdminDelete from './modules/Admins/Delete.vue';
import Admins from './modules/Admins/Index.vue';
import AdminsCreate from './modules/Admins/Create.vue';
import AdminsShow from './modules/Admins/Show.vue';
import Customers from './modules/Customers/Index.vue';
import CustomersCreate from './modules/Customers/Create.vue';
import CustomersDelete from './modules/Customers/Delete.vue';
import CustomersShow from './modules/Customers/Show.vue';
import Stays from './modules/Stays/Index.vue';
import StaysShow from './modules/Stays/Show.vue';
import StaysTypes from './modules/Stays/Types/Index.vue';
import StaysTypesShow from './modules/Stays/Types/Show.vue';
import Promotions from './modules/Promotions/Index.vue';
import ShowPromotions from './modules/Promotions/Show.vue';
import Interests from './modules/Interests/Index.vue';
import ShowInterests from './modules/Interests/Show.vue';
import Assets from './modules/PropertyAssets/Assets/Index.vue';
import AssetsShow from './modules/PropertyAssets/Assets/Show.vue';
import AssetsCategory from './modules/PropertyAssets/Assets/Category/Index.vue';
import AssetsCategoryShow from './modules/PropertyAssets/Assets/Category/Show.vue';
import ContactModule from './modules/ContactModule/Show.vue';
import Locations from './modules/PropertyAssets/Locations/Zones/Index.vue';
import LocationShow from './modules/PropertyAssets/Locations/Zones/Show.vue';
import LocationsCategory from './modules/PropertyAssets/Locations/Category/Index.vue';
import LocationsCategoryShow from './modules/PropertyAssets/Locations/Category/Show.vue';
import Library from './modules/PropertyAssets/Library/Index.vue';
import LibraryShow from './modules/PropertyAssets/Library/Show.vue';
import RatingPateform from './modules/PropertyAssets/Media/RatingPlateform/Index.vue';
import RatingPateformShow from './modules/PropertyAssets/Media/RatingPlateform/Show.vue';
import SocialMedia from './modules/PropertyAssets/Media/SocialMedia/Index.vue';
import SocialMediaShow from './modules/PropertyAssets/Media/SocialMedia/Show.vue';
import CommentProposals from './modules/PropertyAssets/Media/CommentProposals/Index.vue';
import CommentProposalsShow from './modules/PropertyAssets/Media/CommentProposals/Show.vue';
import GuestFlowModule from './modules/GuestFlowModule/IndexBasic.vue';
import GuestFlowModuleShow from './modules/GuestFlowModule/ShowBasic.vue';
import DeviceTypes from './modules/Devices/Types/Index.vue';
import DeviceTypesCreate from './modules/Devices/Types/Create.vue';
import DeviceTypesShow from './modules/Devices/Types/Show.vue';
import Devices from './modules/Devices/Index.vue';
import DevicesCreate from './modules/Devices/Create.vue';
import DevicesShow from './modules/Devices/Show.vue';
import DevicyTypesDelete from './modules/Devices/Types/Delete.vue';
import FileDelete from './modules/Media/DeleteFile.vue';
import FolderDelete from './modules/Media/DeleteFolder.vue';
import Home from './modules/Home.vue';
import Login from './modules/Auth/Login.vue';
import Logout from './modules/Auth/Logout.vue';
import LostPassword from './modules/Auth/LostPassword.vue';
import MainMenu from './modules/MainMenu.vue';
import Media from './modules/Media/Index.vue';
import MediaShow from './modules/Media/Show.vue';
import File from './modules/File/Index.vue';
import FileShow from './modules/File/Show.vue';
import ModuleView from './views/ModuleView.vue';
import PublicView from './views/PublicView.vue';
import PackageCreate from './modules/Packages/Create.vue';
import PackageDelete from './modules/Packages/DeletePackage.vue';
import PackageDetails from './modules/Packages/Show.vue';
import Messages from './modules/Messages/Index.vue';
import MessageDetails from './modules/Messages/Show.vue';
import Packages from './modules/Packages/Index.vue';
import EpgSources from './modules/Epgs/Index.vue';
import EpgSourcesCreate from './modules/Epgs/Create.vue';
import CatalogueShow from './modules/Pages/Items/Show.vue';
import ServiceShow from './modules/Service/Items/Show.vue';
import ApplicationsShow from './modules/Application/Items/Show.vue';
import ProfileCreate from './modules/Accounts/Profiles/CreateProfile.vue';
import ProfileDelete from './modules/Accounts/Profiles/Delete.vue';
import ProfileShow from './modules/Accounts/Profiles/Show.vue';
import ProjectCreate from './modules/Projects/CreateProject.vue';
import Projects from './modules/Projects/Projects.vue';
import Recorders from './modules/Recorders/Index.vue';
import RecordersCreate from './modules/Recorders/Create.vue';
import RecordersShow from './modules/Recorders/Show.vue';
import RecordersStreamCreate from './modules/Recorders/Stream/Create.vue';
import ResetPassword from './modules/Auth/ResetPassword.vue';
import Router from 'vue-router';
import TVChannelsDelete from './modules/TV/Channels/Delete.vue';
import TVChannelsShow from './modules/TV/Channels/Show.vue';
import TVSeriesShow from './modules/Series/List/show.vue';
import seasionsShow from './modules/Series/List/Seasons/show.vue';
import episodesShow from './modules/Series/List/Seasons/Episodes/show.vue';
import TVModuleCreate from './modules/Modules/Create.vue';
import TranscoderStreams from './modules/Transcoder/Index.vue'; // @TODO TranscoderStreAms
import TranscoderStreamsCreate from './modules/Transcoder/Create.vue';
import TranscoderStreamsShow from './modules/Transcoder/Show.vue';
import Users from './modules/Users/Index.vue';
import UsersCreate from './modules/Users/Create.vue';
import UsersDelete from './modules/Users/Delete.vue';
import UsersShow from './modules/Users/Show.vue';
import VODMovieShow from './modules/VOD/Movies/Show.vue';
import Vue from 'vue';
import {VueRouter} from 'vue-router/types/router';
import WeatherLocationsCreate from './modules/Weather/Create.vue';
import stores from '@/stores';

import Dashboards from './modules/Dashboards/STB/Index.vue';
import CreateDashboard from './modules/Dashboards/Create.vue';
import ShowDashboard from './modules/Dashboards/Show.vue';
import DeleteDashboard from './modules/Dashboards/Delete.vue';
import DeletePanel from './modules/Dashboards/Panels/Delete.vue';
import CreateDashboardPanel from './modules/Dashboards/Panels/Create.vue';
import ShowPanel from './modules/Dashboards/Panels/Show.vue';
import CreatePanelElement from './modules/Dashboards/PanelElements/Create.vue';
import ShowPanelElement from './modules/Dashboards/PanelElements/Show.vue';
import DeletePanelElement from './modules/Dashboards/PanelElements/Delete.vue';
import MobileDashboards from '@/modules/Dashboards/Mobile/Index.vue';
import Configuration from '@/modules/Configuration/index.vue';
import AppSetting from '@/modules/Settings/AppSetting.vue';
import Crew from './modules/CrewCast/Crew/Show.vue';
import Cast from './modules/CrewCast/Cast/Show.vue';
import MatomoSetting from '@/modules/Settings/MatomoSetting.vue';
import EpgSetting from '@/modules/Settings/EpgSetting.vue';
import GeneralSetting from '@/modules/Settings/GeneralSetting.vue';
import MailConfiguration from '@/modules/MailConfiguration/Index.vue';
import PaymentsConfiguration from '@/modules/Payments/Index.vue';
import SmsConfiguration from '@/modules/SmsConfiguration/Index.vue';
import ModuleSetting from '@/modules/Settings/ModuleSetting.vue';
import ShowMobileComposer from '@/modules/Dashboards/Mobile/Composer/Show.vue';
import ShowSTBComposer from '@/modules/Dashboards/STB/Composer/Show.vue';
import ShowModule from './modules/Modules/Update.vue';
import LanguageList from './modules/Languages/Index.vue';
import ShowLanguage from './modules/Languages/Show.vue';
import CreateTranslation from './modules/Translation/Create.vue';
import TranslationList from './modules/Translation/Index.vue';
import ShowTranslation from './modules/Translation/Show.vue';
import NevronError404 from './components/NevronError404.vue';

// DYNAMIC COMPONENTS
import CategoriesIndexBasic from '@/components/ListNevronComponents/Categories/IndexBasic.vue';
import CategoriesShowBasic from '@/components/ShowNevronComponents/Categories/ShowBasic.vue';
import CategoriesShowMulti from '@/components/ShowNevronComponents/Categories/ShowMulti.vue';
import ItemsIndex from '@/components/ListNevronComponents/Items/IndexDynamicModules.vue';
import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';

import axios from 'axios';
import routerBase from '@/helpers/routerBase';
// @ts-ignore
import i18n from './i18n.js';
import globalConfig from '@/helpers/globalConfig';
import GFMobileDashboards from '@/modules/Dashboards/GFMobile/Index.vue';
import ShowGFMobileComposer from '@/modules/Dashboards/GFMobile/Composer/Show.vue';

Vue.use(Router);
// TODO: zakaj že rabimo base? https://jsfiddle.net/6xu48og7/1/

const router: VueRouter = new Router({
  mode: 'history',
  base: routerBase,
  routes: [
    {
      component: NevronError404,
      meta: {public: true},
      name: '404',
      path: '*',
    },
    {
      path: '/public',
      component: PublicView,
      children: [
        // public paths
        {
          component: Projects,
          meta: {public: true},
          name: 'projects',
          path: 'projects',
        },
        {
          component: ProjectCreate,
          meta: {public: true},
          name: 'projects.create',
          path: 'projects/create',
        },
        {
          component: MainMenu,
          meta: {public: true},
          name: 'menu',
          path: 'menu',
        },
        {
          component: ResetPassword,
          meta: {public: true},
          name: 'password.reset',
          path: 'password/reset/:token',
        },
        {
          component: LostPassword,
          meta: {public: true},
          name: 'password.lost',
          path: 'password/reset',
        },
      ],

    },
    // project paths
    {
      component: Login,
      meta: {public: true},
      name: 'login',
      path: '/login',
    },
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      name: 'translation.create',
      path: '/translation/create',
      component: CreateTranslation,
    },
    {
      name: 'translation.show',
      path: '/translation/:key',
      component: ShowTranslation,
    },
    {
      name: 'translation.index',
      path: '/translation',
      component: TranslationList,
    },
    {
      component: ItemsIndexGeneric,
      name: 'modules.index',
      path: '/modules',
      props: (route) => ({
        instance: stores.modules,
      }),
    },
    {
      component: ShowModule,
      name: 'modules.show',
      path: '/modules/:id/edit',
    },
    {
      component: AppSetting,
      name: 'app.setting',
      path: '/setting/app',
    },
    {
      component: MatomoSetting,
      name: 'analytic.setting',
      path: '/setting/analytic',
    },
    {
      component: EpgSetting,
      name: 'cron.settings',
      path: '/setting/cron',
    },
    {
      component: ModuleSetting,
      name: 'modules.setting',
      path: '/setting/modules',
    },
    {
      component: GeneralSetting,
      name: 'general.setting',
      path: '/setting/general',
    },
    {
      component: MailConfiguration,
      name: 'general.mail.configuration',
      path: '/mail/configuration',
    },
    {
      component: PaymentsConfiguration,
      name: 'general.payment.settings',
      path: '/payment/settings',
    },
    {
      component: SmsConfiguration,
      name: 'general.sms.configuration',
      path: '/sms/configuration',
    },
    {
      component: Configuration,
      name: 'setting',
      path: '/setting',
    },
    {
      path: '/dashboards', redirect: {name: 'stbDashboards'},
    },
    {
      component: ItemsIndexGeneric,
      name: 'promotions',
      path: '/promotions',
      props: (route) => ({
        instance: stores.Promotions,
      }),
    },
    {
      component: Promotions,
      name: 'create.promotion',
      path: '/promotions/create',
    },
    {
      component: ShowPromotions,
      name: 'promotions.show',
      path: '/promotions/:id',
    },
    {
      component: Interests,
      name: 'interests',
      path: '/interests/:id?',
      props: (route) => ({
        instance: stores.Interests,
      }),
    },
    {
      component: ShowInterests,
      name: 'interests.show',
      path: '/interests/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'assets',
      path: '/assets',
      props: (route) => ({
        instance: stores.Assets,
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'assetCategory',
      path: '/assets/category',
      props: (route) => ({
        instance: stores.AssetCategory,
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'contactModule',
      path: '/contact/module',
      props: (route) => ({
        instance: stores.ContactModule,
      }),
    },
    {
      component: ContactModule,
      name: 'contactModule.show',
      path: '/contact/module/:id',
    },
    {
      component: AssetsCategoryShow,
      name: 'assetCategory.show',
      path: '/assets/category/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'zones',
      path: '/locations',
      props: (route) => ({
        instance: stores.Locations,
      }),
    },
    {
      component: LocationShow,
      name: 'zones.show',
      path: '/locations/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'zoneCategory',
      path: '/category',
      props: (route) => ({
        instance: stores.LocationCategory,
      }),
    },
    {
      component: LocationsCategoryShow,
      name: 'zoneCategory.show',
      path: '/category/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'library',
      path: '/library',
      props: (route) => ({
        instance: stores.Library,
      }),
    },
    {
      component: LibraryShow,
      name: 'library.show',
      path: '/library/show/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'ratingPlatform',
      path: '/rating/plateform',
      props: (route) => ({
        instance: stores.RatingPlatfrom,
      }),
    },
    {
      component: RatingPateformShow,
      name: 'ratingPlatform.show',
      path: '/rating/plateform/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'socialMedia',
      path: '/social-media',
      props: (route) => ({
        instance: stores.SocialMedia,
      }),
    },
    {
      component: SocialMediaShow,
      name: 'socialMedia.show',
      path: '/social-media/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'commentProposal',
      path: '/comment-proposals',
      props: (route) => ({
        instance: stores.CommentProposal,
      }),
    },
    {
      component: CommentProposalsShow,
      name: 'commentProposal.show',
      path: '/comment-proposals/:id',
    },
    {
      component: AssetsShow,
      name: 'assets.show',
      path: '/assets/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'guestflowmodule',
      path: '/guest-flow-module',
      props: (route) => ({
        instance: stores.GuestFlow,
      }),
    },
    {
      component: GuestFlowModuleShow,
      name: 'guestflowmodule.show',
      path: '/guest-flow-module/:id',
      props: (route) => ({
        instance: stores.GuestFlow,
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'stbDashboards',
      path: '/stb-dashboards',
      props: (route) => ({
        instance: stores.STBComposer,
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'mobileDashboards',
      path: '/mobile-dashboards',
      props: (route) => ({
        instance: stores.mobileComposer,
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'gfMobileDashboard',
      path: '/gf-mobile-dashboards',
      props: (route) => ({
        instance: stores.gFMobileComposer,
      }),
    },
    {
      component: ShowGFMobileComposer,
      name: 'gfMobileDashboard.show',
      path: '/composer/gfmobile/:id',
    },
    {
      component: CreateDashboard,
      name: 'dashboards.create',
      path: '/dashboards/create',
    },
    {
      component: ShowDashboard,
      name: 'dashboards.show',
      path: '/dashboards/:id',
    },
    {
      component: DeleteDashboard,
      name: 'dashboards.delete',
      path: 'dashboards/:id/delete/:type?',
    },
    {
      component: ShowMobileComposer,
      name: 'mobileDashboards.show',
      path: '/composer/mobile/:id',
    },
    {
      component: ShowSTBComposer,
      name: 'stbDashboards.show',
      path: '/composer/stb/:id',
    },
    {
      component: CreateDashboardPanel,
      name: 'dashboards-panel.create',
      path: '/dashboard-panel/create/:id',
    },
    {
      component: ShowPanel,
      name: 'dashboard-panel.show',
      path: '/dashboard-panel/:id',
    },
    {
      component: DeletePanel,
      name: 'dashboards-panel.delete',
      path: '/dashboards/:id/panel/:panelId/delete/:type?',
    },
    {
      component: CreatePanelElement,
      name: 'panel.element.create',
      path: '/panel-element/:id/create',
    },
    {
      component: ShowPanelElement,
      name: 'panel.element.show',
      path: '/panel-element/:id/:type?',
    },
    {
      component: DeletePanelElement,
      name: 'panel.element.delete',
      path: '/panel-element/:id/delete',
    },
    {
      component: ItemsIndexGeneric,
      name: 'languages',
      path: '/languages',
      props: (route) => ({
        instance: stores.Language,
      }),
    },
    {
      component: ShowLanguage,
      name: 'languages.show',
      path: '/languages/:id',
    },
    {
      component: PackageCreate,
      name: 'packages.create',
      path: '/packages/create',
    },
    {
      component: PackageDelete,
      name: 'packages.delete',
      path: '/packages/:id/delete',
    },
    {
      component: PackageDetails,
      name: 'packages.show',
      path: '/packages/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'packages',
      path: '/packages',
      props: (route) => ({
        instance: stores.Package,
      }),
    },
    {
      component: MessageDetails,
      name: 'messages.show',
      path: '/messages/:id',
    },
    {
      component: Messages,
      name: 'messages',
      path: '/messages',
    },
    {
      children: [
        {
          component: ItemsIndexGeneric,
          name: 'clock',
          path: 'clock',
          props: (route) => ({
            moduleId: parseInt(route.params.moduleId, 10),
          }),
        },
      ],
      component: ModuleView,
      path: '/time/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TIME',
      }),
    },
    {
      children: [
        {
          component: WeatherLocationsCreate,
          name: 'locations.create',
          path: 'locations/create',
        },
        {
          component: ItemsIndexGeneric,
          name: 'locations',
          path: 'locations',
        },
      ],
      component: ModuleView,
      path: '/weather/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'WEATHER',
      }),
    },
    {
      children: [
        {
          component: CatalogueShow,
          name: 'catalogue.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'catalogue.items',
          path: 'catalogue_items',
        },
        {
          component: CategoriesShowMulti,
          name: 'catalogue.categories',
          path: 'categories/:id?',
        },
      ],
      component: ModuleView,
      path: '/catalogue/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'CATALOGUE',
      }),
    },
    {
      children: [
        {
          component: ServiceShow,
          name: 'service.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'service.items',
          path: 'service_items',
        },
        {
          component: CategoriesShowMulti,
          name: 'service.categories',
          path: 'service/:id?',
        },
      ],
      component: ModuleView,
      path: '/service/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'SERVICE',
      }),
    },
    {
      children: [
        {
          component: ApplicationsShow,
          name: 'applications.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'applications.items',
          path: 'applications_items',
        },
        {
          component: CategoriesIndexBasic,
          name: 'applications.categories',
          path: 'categories',
          props: (route) => ({
            tableName: 'application_categories',
          }),
        },

        {
          component: CategoriesShowBasic,
          name: 'applications.categories.show',
          path: 'categories/:id',
        },
      ],
      component: ModuleView,
      path: '/applications/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'APPLICATION',
      }),
    },
    {
      component: ProfileCreate,
      name: 'profile.create',
      path: '/accounts/:id/profile/create',
    },
    {
      component: ProfileDelete,
      name: 'accounts.profile.delete',
      path: '/accounts/:acc/profile/:id/delete',
    },
    {
      component: ProfileShow,
      name: 'accounts.profile.show',
      path: '/accounts/:acc/profile/:id',
    },
    {
      component: UsersCreate,
      name: 'users.create',
      path: '/users/create',
    },
    {
      component: UsersDelete,
      name: 'users.delete',
      path: '/users/:id/delete',
    },
    {
      component: UsersShow,
      name: 'users.show',
      path: '/users/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'users',
      path: '/users',
      props: (route) => ({
        instance: stores.User,
      }),
    },
    {
      component: Logout,
      name: 'logout',
      path: '/logout',
    },
    {
      component: AdminDelete,
      name: 'admin.delete',
      path: '/admins/:id/delete',
    },
    {
      component: Admins,
      name: 'admins',
      path: '/admins',
    },
    {
      component: AdminsCreate,
      name: 'admins.create',
      path: '/admins/create',
    },
    {
      component: AdminsShow,
      name: 'admins.show',
      path: '/admins/:id',
    },
    {
      component: TVModuleCreate,
      name: 'modules.create',
      path: '/modules/create',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TV_RADIO',
      }),
    },
    {
      component: EpgSources,
      name: 'epgSources',
      path: '/epg/sources',
    },
    {
      component: EpgSourcesCreate,
      name: 'epgSources.create',
      path: '/epg/sources/create',
    },
    {
      component: ItemsIndexGeneric,
      name: 'accounts',
      path: '/accounts',
      props: (route) => ({
        instance: stores.accounts,
      }),
    },
    {
      component: AccountsCreate,
      name: 'accounts.create',
      path: '/accounts/create',
    },
    {
      component: AccountsShow,
      name: 'accounts.show',
      path: '/accounts/:id',
    },
    {
      component: AccountDelete,
      name: 'accounts.delete',
      path: '/accounts/:id/delete',
    },
    {
      component: Media,
      name: 'media.folder',
      path: '/media/folder/:folderId',
    },
    {
      component: FolderDelete,
      name: 'media.folder.delete',
      path: '/media/folder/:folderId/delete',
    },
    {
      component: MediaShow,
      name: 'media.file.show',
      path: '/media/file/:fileId',
    },
    {
      component: FileDelete,
      name: 'media.file.delete',
      path: '/media/file/:fileId/delete',
    },
    {
      component: File,
      name: 'file.library',
      path: '/file/library',
    },
    {
      component: FileShow,
      name: 'file.library.show',
      path: '/file/library/:fileId',
    },
    {
      children: [
        {
          component: RecordersStreamCreate,
          name: 'recorders.stream.create',
          path: '/recorders/:id/create',
        },
        {
          component: RecordersCreate,
          name: 'recorder.create',
          path: 'create',
        },
        {
          component: RecordersShow,
          name: 'recorder.show',
          path: 'recording/:id',
        },
        {
          component: Recorders,
          name: 'recorders',
          path: 'index',
        },
      ],
      component: ModuleView,
      path: '/recorders/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'RECORDER',
      }),
    },
    {
      children: [
        {
          component: TranscoderStreamsCreate,
          name: 'transcoder.streams.create',
          path: 'streams/create',
        },
        {
          component: TranscoderStreamsShow,
          name: 'transcoder.streams.show',
          path: 'streams/:id',
        },
        {
          component: TranscoderStreams,
          name: 'transcoder.streams',
          path: 'streams',
        },
      ],
      component: ModuleView,
      path: '/transcoder/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TRANSCODER',
      }),
    },
    {
      children: [
        {
          component: CategoriesIndexBasic,
          name: 'vod.categories',
          path: 'categories',
          props: (route) => ({
            tableName: 'vod_categories',
          }),
        },
        {
          component: CategoriesShowBasic,
          name: 'vod.categories.show',
          path: 'categories/:id',
        },
        {
          component: VODMovieShow,
          name: 'vod.items.show',
          path: 'movies/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'vod.items',
          path: 'movies',
        },
      ],
      component: ModuleView,
      path: '/vod/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'VOD',
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'devices',
      path: '/devices',
      props: (route) => ({
        instance: stores.Devices,
      }),
    },
    {
      component: DevicesCreate,
      name: 'devices.create',
      path: '/devices/create',
    },
    {
      component: DevicesShow,
      name: 'devices.show',
      path: '/devices/:id',
    },
    {
      component: Home,
      name: 'home',
      path: '/dashboard',
    },
    {
      children: [
        {
          component: CategoriesShowBasic,
          name: 'tv.categories.show',
          path: 'categories/:id',
        },
        {
          component: CategoriesIndexBasic,
          name: 'tv.categories',
          path: 'categories/',
          props: (route) => ({
            tableName: 'tv_categories',
          }),
        },
        {
          component: TVChannelsDelete,
          name: 'tv.items.delete',
          path: 'channels/:id/delete',
        },
        {
          component: TVChannelsShow,
          name: 'tv.items.show',
          path: 'channels/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'tv.items',
          path: 'channels/',
        },
      ],
      component: ModuleView,
      name: 'tv',
      path: '/tv/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TV_RADIO',
      }),
    },
    {
      children: [
        {
          component: episodesShow,
          name: 'episodes.show',
          path: 'series/:seriesId?/seasons/:seasonsId?/episodes/:id?',
          props: (route) => ({
            id: parseInt(route.params.id, 10),
            seriesId: parseInt(route.params.seriesId, 10),
          }),
        },
        {
          component: episodesShow,
          name: 'trailor.show',
          path: 'series/:seriesId?/episodes/:id?',
        },
        {
          component: Crew,
          name: 'crews.show',
          path: '/crew/:crewId',
        },
        {
          component: Cast,
          name: 'casts.show',
          path: '/cast/:castId',
        },
        {
          component: seasionsShow,
          name: 'seasons.show',
          path: 'series/:seriesId/seasons/:id',
          props: (route) => ({
            seriesId: parseInt(route.params.seriesId, 10),
          }),
        },
        {
          component: TVSeriesShow,
          name: 'series.items.show',
          path: 'series/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'series.items',
          path: 'series/',
        },
        {
          component: CategoriesIndexBasic,
          name: 'series.categories',
          path: 'category/',
          props: (route) => ({
            tableName: 'genres',
          }),
        },
        {
          component: CategoriesShowBasic,
          name: 'series.categories.show',
          path: 'categories/:id',
        },
      ],
      component: ModuleView,
      path: '/series/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'SERIES',
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'customers',
      path: '/customers',
      props: (route) => ({
        instance: stores.Customer,
      }),
    },
    {
      component: CustomersCreate,
      name: 'customers.create',
      path: '/customers/create',
    },
    {
      component: CustomersShow,
      name: 'customers.show',
      path: '/customers/:id',
    },
    {
      component: CustomersDelete,
      name: 'customers.delete',
      path: '/customers/:id/delete',
    },
    {
      component: ItemsIndexGeneric,
      name: 'stays',
      path: '/stays',
      props: (route) => ({
        instance: stores.Stays,
      }),
    },
    {
      component: StaysShow,
      name: 'stays.show',
      path: '/stays/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'stayTypes',
      path: '/stay/category',
      props: (route) => ({
        instance: stores.StayTypes,
      }),
    },
    {
      component: StaysTypesShow,
      name: 'stayTypes.show',
      path: '/stay/category/:id',
    },
    {
      component: AddressCreate,
      name: 'customers.address.create',
      path: '/customers/:id/address/create',
    },
    {
      component: AccountsCreate,
      name: 'customer.account.create',
      path: '/customers/:id/account/create',
    },
    {
      component: DeviceTypes, // TODO: MAKS: tole anžetu ful ni všeč da je tkole z minusom routa, probi dat nazaj na /devices/types a hkrati se mora samo izbrana pobarvat, nazadne nism najdu css-a v routerju :(
      name: 'devices.types',
      path: '/device-types',
    },
    {
      component: DeviceTypesShow,
      name: 'devices.types.show',
      path: '/device-types/:id',
    },
    {
      component: DevicyTypesDelete,
      name: 'devices.types.delete',
      path: '/device-types/:id/delete',
    },
    {
      component: DeviceTypesCreate,
      name: 'devices.types.create',
      path: '/device-types/create',
    },
  ],
});

/*
if (typeof stores.session.project === 'undefined' && window.location.pathname !== '/projects') {
  if (globalConfig.env === 'development') {
    console.log('redirecting to projects');
    router.replace({name: 'projects'});
  } else {
    router.replace({name: '404'});
  }
}
*/

router.beforeEach((to, from, next) => {

  stores.admin.fetch().catch(() => {
    stores.admin.logout();
  });

  if (!to.meta?.public && !stores.admin.isLoggedIn) {
    if (router.options.base !== '/') {
      next({
        path: '/login',
        query: {redirect: to.fullPath},
      });
    } else {
      if (globalConfig.env !== 'development') {
        next({name: '404'});
      } else {
        next({name: 'projects'});
      }
    }
  } else {
    next();
  }

  // prevent disabled scroll
  document.body.style.overflow = 'auto';

});
export default router;
