import axiosInstance from '@/helpers/axios';
import {Vue} from 'vue-property-decorator';
import stores from '@/stores';

export function exportData(tableName: string, ids: number[], columns?: string[]) {
  return axiosInstance.post(`export/${tableName}`, {ids})
    .then((response) => response.data).catch((e) => {
      const log: any = {
        route: tableName,
        message: e,
      };
      generateLog(log);
    });
}

export function importData(data: any, tableName: string) {
  return axiosInstance.post(`import/${tableName}`, {data})
    .then((response) => response.data).catch((e) => {
      const log: any = {
        route: tableName,
        message: e,
      };
      generateLog(log);
    });
}

export function translatedDisplayName(item: IItem) {
  if (item.username) {
    return item.username;

  } else if (item.name) {
    return Vue.prototype.translate(item.name);

  } else if (item.firstName && item.lastName) {
    const string = `${Vue.prototype.translate(item.firstName)} ${Vue.prototype.translate(item.lastName)}`;
    if (item.salutation) {
      return `${Vue.prototype.translate(item.salutation)} ${string}`;
    }
    return string;

  } else if (item.macEth) {
    return item.macEth;

  } else if (item.macWifi) {
    return item.macWifi;

  } else if (item.city) {
    return Vue.prototype.translate(item.city);

  } else if (item.mainCustomer) {
    return `${Vue.prototype.translate(item.mainCustomer.firstName)} ${Vue.prototype.translate(item.mainCustomer.lastName)}  ${Vue.prototype.translate('stay')}`;

  } else {
    return '';
  }
}

export function generateLog(object: any) {
  console.log('Log:', object);
}
