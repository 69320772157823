
import {Component, Vue, Watch} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
// @ts-ignore
import Switches from 'vue-switches';
import {Setting} from "@/stores/Setting";

@Component({
  components: {
    NevronHeader,
    Skeleton,
    Switches,
  },
})

export default class PaymentsDetails extends Vue {
  mail: any = '';
  response: any = '';
  paymentOptions: ISetting  = {
    id: 0,
    key: 'payment_options',
    value: '1',
    type: 'payment',
  };
  secretKey: ISetting  = {
    id: 0,
    key: 'secret_key',
    value: 'secret',
    type: 'payment',
  };
  publicKey: ISetting  = {
    id: 0,
    key: 'public_key',
    value: 'secret',
    type: 'payment',
  };


  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetch();
  }

  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
        console.log(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'payment_options') {
        this.paymentOptions = data[index];
        this.paymentOptions.value = data[index].value;
      } else if (data[index].key === 'secret_key') {
        this.secretKey = data[index];
        this.secretKey.value = data[index].value;
      } else if (data[index].key === 'public_key') {
        this.publicKey = data[index];
        this.publicKey.value = data[index].value;
      }
    }
  }

  /*
   *
   */
  onSubmit(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        console.log(response);
        this.fetch();
        console.log('setting updated successfully..');
      });
  }
}
