
import globalConfig from '@/helpers/globalConfig';
import axios from 'axios';
import {generateLog} from '@/helpers/functions';

export default class Master {

    url: string;
    path: string;
    fullUrl: string;
    projects?: IManagerProject[] | null = null;
    routeName: string = 'Master';

    constructor() {
        this.url = globalConfig.url;
        this.path = globalConfig.path;
        this.fullUrl = `${globalConfig.url}/${globalConfig.path}`;
    }

    getProjects() {
        return axios.get(`${this.fullUrl}/tenants`)
        .then((response) => {this.projects = response.data; return this.projects; })
          .catch((e) => {
            const log: any = {
              route: this.routeName,
              message: e,
            };
            generateLog(log);
          });
    }

    getSpecificProject(projectId: number | string) {
        return axios.get(`${this.fullUrl}/tenants/${projectId}`)
        .then((response) => response.data)
          .catch((e) => {
            const log: any = {
              route: this.routeName,
              message: e,
            };
            generateLog(log);
          });
    }

    createProject(project: string) {
        return axios.post(`${this.fullUrl}/tenants`, {project})
            .then((response) => response.data).catch((e) => {
            const log: any = {
              route: this.routeName,
              message: e,
            };
            generateLog(log);
          });
    }

    deleteProject(projectId: number | string) {
        return axios.delete(`${this.fullUrl}/tenants/${projectId}`)
            .then((response) => response.data).catch((e) => {
            const log: any = {
              route: this.routeName,
              message: e,
            };
            generateLog(log);
          });
    }
}
