
import PageHeader from '@/components/partials/page-header.vue';
import PieChart from '@/components/partials/widgets/PieChart.vue';
import BarChart from '@/components/partials/widgets/BarChart.vue';
import ModuleCard from '@/components/partials/widgets/ModuleCard.vue';
import Profile from '@/components/partials/widgets/profile.vue';
import axiosInstance from '@/helpers/axios';
import {Vue, Component, Watch} from 'vue-property-decorator';
import stores from '../stores';
import header from '@/components/partials/header.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import projects from '../helpers/projects';

@Component({
  components: {
    NevronHeader,
    ModuleCard,
    PageHeader,
    Profile,
    PieChart,
    BarChart,
    header,
  },
})
export default class Home extends Vue {
  page: any = {
    title: 'Dashboard',
    meta: [{name: 'description', content: 'Nevron Manager Dashboard'}],
  };
  header: any = header;
  title: any = 'Dashboard';
  items: any = [
    {
      text: 'Dashboards',
      href: '/',
    },
    {
      text: 'Default',
      active: true,
    },
  ];
  basicData: any = {
    device: 0,
    account: 0,
    customer: 0,
    vod: 0,
    tv: 0,
    moduleCount: 0,
    modules: [],
  };
  modules: any = [];
  accountData: [] = [];
  devicesData: [] = [];
  moviesData: [] = [];
  tvData: [] = [];
  isMatomoUrlExist: boolean = false;
  islanguage: boolean = true;

  // modules list next page
  moduleNext() {
    if (this.modules.currentPage < this.modules.lastPage) {
      this.getBasicData(this.modules.currentPage + 1);
    }
  }

  // module list pre page
  modulePre() {
    if (this.modules.currentPage > 1) {
      this.getBasicData(this.modules.currentPage - 1);
    }
  }

  // get analytic data which is matomo based
  getWatchedData() {
    stores.Matomo.getAnalytic()
      .then((response) => {
        if (response && response.tv && response.vod) {
          this.tvData = response.tv;
          this.moviesData = response.vod;
        }
      });
  }

  getBasicData(id = 1) {
    stores.Matomo.getHomeData(id)
      .then((response) => {
        this.basicData = response;
        this.modules = response.modules;
        this.accountData = response.pie.accounts;
        this.devicesData = response.pie.devices;

        setTimeout(() => {

          // @ts-ignore
          this.$bus.$emit('pieload', 'pie load');
        }, 2000);

      });
  }

  created() {
    // @ts-ignore
    this.$bus.$emit('logged', 'User logged');
    this.loadData();
    window.addEventListener('resize', this.loadData);
  }

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('dashboard');
  }

  loadData() {
    stores.Setting.getKeySetting('matomo_agent_url  ')
      .then((response: any) => {
        if (response) {
          this.isMatomoUrlExist = true;
        } else {
          this.isMatomoUrlExist = false;
        }
      }).then(() => {
      if (this.isMatomoUrlExist) {
        this.getWatchedData();
        this.getBasicData();
      }
    });
  }
}
