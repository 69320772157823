
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import hexColorCode from '@/helpers/hexColorCode';
import getInitials from '@/helpers/initials';
import _ from 'lodash';
import PerPageDropdown from '../../components/PerPageDropdown.vue';
import CreateFile from '@/modules/File/CreateFile.vue';
import EditFolder from '@/modules/Media/EditFolder.vue';

@Component({
  components: {
    vPagination,
    NevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    NevronClickConfirm,
    PerPageDropdown,
    CreateFile,
    EditFolder,
  },
})
export default class FileList extends Vue {
  response: any = null;

  firstLoad = true;
  perpage = 10;
  openedItem: any = null;
  indexList: number[] = [];
  settingId: any;

  search: any = {
    query: '',
    current: '',
  };
  selectedFiles: number[] = [];
  selectedFolders: number[] = [];
  searchItems = _.debounce(() => {
    this.fetchData(1, this.search.query, this.perpage);
  }, 400);

  /*
   * Watch for pagination
   */
  @Watch('response.currentPage')
  ChangePage() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.search.query = this.search.current;
    this.fetchData(
      this.response.currentPage,
      this.search.current,
      this.perpage,
    );
  }

  updateCheck(index: number) {
    this.indexList = [];
    this.indexList.push(index);
  }

  perPageChange() {
    this.fetchData(1, this.search.query, this.perpage);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('file-library');
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }

    // set page from URL
    let index = 1;
    if (this.$route.query.page) {
      index = Number(this.$route.query.page);
    }
    // set perpage from URL
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }
    // load data
    this.fetchData(index, this.search.current, this.perpage);
  }

  refresh() {
    this.fetchData(this.openedItem.currentPage, null, this.perpage);
  }

  openItem(isFile: any, id: number) {
    this.$router.push({name: 'file.library.show', params: {fileId: String(id)}});
  }

  /**
   * Fetches data on the specified page and with the specified query
   */
  fetchData(
    index: number | null,
    search: string | null,
    perpage: number | 10,
  ) {
    stores.Setting
      .fetchIcon(index, search, perpage)
      .then((response) => {
        console.log(search);
        this.response = response.data;
        this.openedItem = response.data.data;

      })
      .catch((error) => {
        console.log(error);
      });
  }

  getInitials(str: string) {
    return getInitials(str);
  }

  /*
   * Get hex color code from string
   */
  hexColorCode(str: string) {
    return hexColorCode(str);
  }
  get selectedLength() {
    return this.selectedFiles.length + this.selectedFolders.length;
  }
}
