
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class VODSources extends Vue {
  @Prop() source!: IVODSource | null;

  newSource: IVODSource | null = null;

  @Watch('source', { immediate: true })
    setModuleFromProps() {

    if (this.source) {
      this.newSource = JSON.parse(JSON.stringify(this.source));
    } else {
      this.newSource = {
        id: null,
        movieId: null,
        source: '',
        quality: null,
        sort: null,
      };
    }
  }

  save(): void {
    if (this.newSource) {
      if (this.source === null) {
        this.$emit('saveNew', this.newSource);
      } else {
        this.$emit('saveEdit', this.newSource);
      }
      // @ts-ignore
      this.$children[0].close();
    }
  }
  cancel(): void {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }
}
