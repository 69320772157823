
import { Component, Vue, Prop } from 'vue-property-decorator';

import stores from '@/stores';
import RecorderModule from '@/stores/modules/Recorders';

@Component({
  components: {
  },
})

export default class CreateTVRecorders extends Vue {
  @Prop()
  module!: RecorderModule;

  recorder: IRecorder = {
    id: null,
    name: '',
    ip: '',
    port: null,
    type: 'NEVRON',
    username: '',
    password: '',
    streams: [],
  };

  save() {
    return this.module.createRecorders(this.recorder)
      .then((response) => {
        console.log('You have successfully created a new recorder');
        this.cancel();
      })
      .catch((e) => {
        console.log(e);
      });
  }
  cancel(): void {
    this.recorder = {
      id: null,
      name: '',
      ip: '',
      port: null,
      type: 'NEVRON',
      username: '',
      password: '',
      streams: [],
    };
    this.$router.push('/recorders');
  }
}
