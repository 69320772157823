
import {Component, Vue, Watch} from 'vue-property-decorator';
import AdminChangePassword from '@/modules/Admins/ChangePassword.vue';
import {Admin} from '../../stores/Admins';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronInput from '@/components/NevronInput.vue';

@Component({
  components: {
    AdminChangePassword,
    NevronHeader,
    Skeleton,
    NevronInput,
  },
})

export default class AdminDetails extends Vue {
  currentAdmin = stores.admin;
  admin: Admin = new Admin();

  /*
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(this.$route.params.id);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(this.$route.params.id);
  }

  /*
   *
   */
  fetchData(id: string) {
    Admin.getById(id)
      .then((admin) => {
        this.admin = admin;
        console.log('This admin', this.admin);
      })
      .catch((reason) => {
        console.log('Could not fetch admin', reason);
        if (!this.admin.id) {
          this.$router.push({name: 'admins'});
        }
      });
  }

  /*
   *
   */
  onSubmit() {
    const data = {
      id: this.admin.id,
      name: this.admin.name,
      username: this.admin.username,
      email: this.admin.email,
    };

    return stores.admins.update(data)
      .then(() => {
        console.log('success');
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /*
   *
   */
  onSubmitStatus() {
    console.log(this.admin.status);
    return stores.admins.update({id: this.admin.id, status: this.admin.status})
      .then(() => {
        console.log('success');
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /*
   *
   */
  editPass(user: IAdmin) {
    if (user.id) {
      return stores.admins.changePassword(user.id, user)
        .then(() => {
          user.password = '';
          user.passwordConfirmation = '';
          // @ts-ignore
          this.$refs.pass.$children[0].close();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  /*
   *
   */
  removeAdmin() {
    if (this.admin.id) {
      return stores.admins.delete(this.admin.id)
        .then(() => {
          console.log('Success');
          this.$router.push('/admins?page=1');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
}
