
import {Component, Vue} from 'vue-property-decorator';
import globalConfig from '../../stores/GlobalConfig';
import stores from '@/stores';

@Component
export default class CreateProject extends Vue {
  config = globalConfig.config;

  project = '';

  error = '';
  loading = false;

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };

  validate() {
    if (this.project.length === 0) {
      this.error = 'Required field';
    } else if (this.project && !(/^[a-zA-Z0-9 ]+$/.test(this.project))) {
      this.error = 'Name can only contain letters, numbers and spaces.';
    } else {
      this.error = '';
    }
    return this.error.length === 0;
  }

  onSubmit() {
    if (this.validate()) {
      this.loading = true;
      stores.master.createProject(this.project)
        .then((response) => {
          if (!response.hasOwnProperty('tenantId')) {
            this.error = response.message;
            this.loading = false;
          } else {
            window.location.href = `${window.location.origin}/${response.tenantId}`;
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.error = error.response.data.message;
            this.loading = false;
          }
        });
    }

  }
}
