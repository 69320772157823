window.GlobalConfig = window.GlobalConfig || {};

function globalConfig() {
    let config: any = {};

    if (typeof window.GlobalConfig !== 'undefined') {
        config = window.GlobalConfig;
    }
    config.path = (config.path[0] === '/' ? config.path.slice(1) : config.path);

    return config;
}

export default globalConfig();
